const DEFAULT_SETTINGS = {
  /*
  fieldId: { // Same ID as supplied to <Input />
    value: '',
    defaultValue: '', // Update StepReview.jsx & relevant step(s) if you need to output
  }
  */

  /* Default Advanced Settings */

  throttleEnabled: {
    value: false, // We want to default to true on render, but never compare the default
  },
  subscribeToAllLog: {
    value: true, // We want to default to true on render, but never compare the default
  },

  mimecast: {
    value: '',
  },

  mimecastThrottleEnabled: {
    value: undefined,
  },

  applicationKey: {
    value: '',
  },

  pollingInterval: {
    value: 5,
  },
  contentTypes: {
    value: '',
  },
  availableContents: {
    value: 'ARCHIVE_MESSAGE_VIEW,ARCHIVE_SEARCH,AUDIT_EVENTS,DLP,MESSAGE_RELEASE,REJECTION,SEARCH,TTP_ATTACHMENT_PROTECTION,TTP_IMPERSONATION_PROTECT,TTP_URL',
  },
};

export default DEFAULT_SETTINGS;
