import * as React from 'react';

import { Alert } from 'components/bootstrap';

const DataLakeStreamDeleteWarning = () => (
  <Alert bsStyle="danger">
    Data Lake is enabled on this stream and have data. <br />
    Please disable it and delete the data before deleting the stream.
  </Alert>
);

export default DataLakeStreamDeleteWarning;
