import * as React from 'react';
import styled from 'styled-components';

import { Button, ControlLabel, Label } from 'components/bootstrap';
import { IconButton, Icon } from 'components/common';
import type { SecurityEventAPIType } from 'security-app/hooks/api/securityEventsAPI.types';
import { useModalContext } from 'common/contexts';
import { useGetUsers, useGetTeams } from 'security-app/hooks/useInvestigationsAPI';
import type { UserAPIType, TeamAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import useCurrentUser from 'hooks/useCurrentUser';
import { TD } from 'security-app/components/common';
import SetStatusControl from 'security-app/components/SecurityEvents/Alerts/EventDetails/SetStatusControl';
import useSendEventActionTelemetry from 'components/events/events/hooks/useSendEventActionTelemetry';

import { LEFT_COLUMN_WIDTH, RIGHT_COLUMN_WIDTH } from './EventDetails';

const Row = styled.div<{ $gap?: string, $justify?: string, $align?: string, $orientation?: 'horz' | 'vert' }>`
  display: flex;
  flex-direction: ${({ $orientation }) => ($orientation === 'vert' ? 'column' : 'row')};
  justify-content: ${({ $justify }) => ($justify || 'space-between')};
  align-items: ${({ $align, $orientation }) => ($orientation === 'vert' ? 'flex-start' : ($align || 'center'))};
  gap: ${({ $gap }) => ($gap || '1rem')};
`;

const LeftControls = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: ${LEFT_COLUMN_WIDTH};
`;

const RightControls = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: ${RIGHT_COLUMN_WIDTH};
`;

const FlexLabel = styled(Label)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

type Props = {
  securityEvent: SecurityEventAPIType,
  onReplaySearch: () => void,
  orientation?: 'horz' | 'vert',
};

function ActionsRow({ securityEvent, onReplaySearch, orientation = 'horz' }: Props) {
  const { setModal, setEntity } = useModalContext();
  const sendEventActionTelemetry = useSendEventActionTelemetry();

  const { users } = useGetUsers();
  const { teams } = useGetTeams();
  const currentOwnerName = React.useMemo(() => {
    const owner = users.find((user: UserAPIType) => user.id === securityEvent.owner);

    return owner ? owner.full_name : teams.find((team: TeamAPIType) => team.id === securityEvent.owner)?.name;
  }, [users, teams, securityEvent.owner]);

  const replaySearch = () => {
    sendEventActionTelemetry('REPLAY_SEARCH', false);

    if (typeof onReplaySearch === 'function') {
      onReplaySearch();

      return;
    }

    setEntity(securityEvent);
    setModal('REPLAY-SEARCH-VIEW');
  };

  const openEvidenceModal = () => {
    const { event } = securityEvent;
    setEntity(event);
    setModal('ADD-EVIDENCE');
  };

  const sendNotification = () => {
    setEntity(securityEvent);
    setModal('SEND-NOTIFICATION');
  };

  const onStatusChange = (newStatus: string) => {
    const newSecurityEvent = { ...securityEvent, status: newStatus };
    setEntity(newSecurityEvent);
    setModal('CONFIRM-STATUS-CHANGE');
  };

  const selectOwner = () => {
    setEntity(securityEvent);
    setModal('SELECT-OWNER');
  };

  const onRemoveOwner = () => {
    setEntity(securityEvent);
    setModal('CONFIRM-OWNER-REMOVE');
  };

  const hideReplayAction = !!securityEvent.event.event_definition_type.match(/system-notification|anomaly/);

  const { permissions } = useCurrentUser();
  const canManageSecurityEvents = React.useMemo(() => (
    permissions.includes('security_event:edit') || permissions.includes('*')
  ), [permissions]);

  return (
    <Row $orientation={orientation}>
      <LeftControls>
        {hideReplayAction || <Button bsStyle="primary" bsSize="sm" onClick={replaySearch}>Replay Search</Button>}
        {!canManageSecurityEvents || <Button bsSize="sm" onClick={openEvidenceModal}>Add to an Investigation</Button>}
        {!canManageSecurityEvents || <Button bsSize="sm" onClick={sendNotification}>Send Notification</Button>}
      </LeftControls>
      <RightControls>
        {canManageSecurityEvents ? (
          <Row $gap="0.5rem">
            <ControlLabel style={{ margin: 0 }}>Owner</ControlLabel>
            {!!currentOwnerName && <FlexLabel>{currentOwnerName}<StyledIcon name="close" onClick={onRemoveOwner} /></FlexLabel>}
            {!!currentOwnerName || <IconButton name="add" onClick={selectOwner} title="Add owner" />}
          </Row>
        ) : <TD />}
        <Row>
          <SetStatusControl onStatusChange={onStatusChange} value={securityEvent.status} disabled={!canManageSecurityEvents} />
        </Row>
      </RightControls>
    </Row>
  );
}

export default ActionsRow;
