import * as React from 'react';

import type { DataLake } from 'data-lake/Types';
import RestoredArchivesCell from 'data-lake/data-lake-overview/cells/RestoredArchivesCell';
import Routes from 'routing/Routes';
import type { ColumnRenderers } from 'components/common/EntityDataTable';
import { Timestamp } from 'components/common';
import { Link } from 'components/common/router';

import DaysOfData from './cells/DaysOfData';

const customColumnRenderers = (): ColumnRenderers<DataLake> => ({
  attributes: {
    last_known_stream_title: {
      renderCell: (_last_known_stream_title: string, dataLake) => dataLake.last_known_stream_title,
      width: 0.2,
    },
    stream: {
      renderCell: (_last_known_stream_title: string, dataLake) => (
        <Link to={`${Routes.stream_view(dataLake.stream_id)}?segment=destinations`}>{dataLake.last_known_stream_title}</Link>
      ),
      width: 0.2,
    },
    days_of_data: {
      renderCell: (_days_of_data: string, dataLake) => <DaysOfData dataLake={dataLake} />,
      width: 0.1,
    },
    message_count: {
      width: 0.1,
    },
    restored_indices: {
      renderCell: (_restored_indices: string, archive) => <RestoredArchivesCell archive={archive} />,
      width: 0.1,
    },
    timestamp_from: {
      renderCell: (_timestamp_from: string, archive) => archive?.timestamp_from
        && <Timestamp dateTime={archive.timestamp_from} />,
      width: 0.1,
    },
    timestamp_to: {
      renderCell: (_timestamp_to: string, archive) => archive?.timestamp_to
        && <Timestamp dateTime={archive.timestamp_to} />,
      width: 0.1,
    },
  },
});

export default customColumnRenderers;
