import * as React from 'react';

import { ConfirmDialog } from 'security-app/components/common/Modal';
import { useModalContext } from 'common/contexts';
import { useUpdateSecurityEvent } from 'security-app/hooks/useSecurityEventsAPI';
import { SECURITY_EVENT_STATUSES } from 'security-app/hooks/api/securityEventsAPI.types';
import useSendEventActionTelemetry from 'components/events/events/hooks/useSendEventActionTelemetry';

function ConfirmStatusChange() {
  const { entity: securityEvent, setEntity, setModal } = useModalContext();
  const { updateSecurityEvent, updatingSecurityEvent } = useUpdateSecurityEvent();
  const sendEventActionTelemetry = useSendEventActionTelemetry();

  const onConfirm = async () => {
    const payload = {
      owner: securityEvent.owner,
      notes: securityEvent.notes,
      status: securityEvent.status,
    };

    await updateSecurityEvent({ id: securityEvent.event_id, data: payload });
    sendEventActionTelemetry('SET_STATUS', false);

    setModal(null);
    setEntity(null);
  };

  const onCancel = () => {
    setModal(null);
    setEntity(null);
  };

  return (
    <ConfirmDialog show
                   onConfirm={onConfirm}
                   confirmDisabled={updatingSecurityEvent}
                   isLoading={updatingSecurityEvent}
                   confirmText="Confirm"
                   onCancel={onCancel}
                   title="Change Event Status">
      <p>Are you sure you want to change the status to <b>{SECURITY_EVENT_STATUSES[securityEvent.status]}?</b></p>
    </ConfirmDialog>
  );
}

export default ConfirmStatusChange;
