import * as React from 'react';

import PageNavigation from 'components/common/PageNavigation';
import DataLakeRoutes from 'data-lake/Routes';
import { Row } from 'components/bootstrap';
import { filterCloudMenuItems } from 'util/conditional/filterMenuItems';

const NAV_ITEMS = [
  { title: 'Overview', path: DataLakeRoutes.ARCHIVE.LIST, exactPathMatch: true },
  { title: 'Backend', path: DataLakeRoutes.BACKEND, exactPathMatch: true },
  { title: 'Configuration', path: DataLakeRoutes.CONFIGURATION, exactPathMatch: true },
];
const navItems = filterCloudMenuItems(NAV_ITEMS, [DataLakeRoutes.BACKEND]);

const DataLakePageNavigation = () => (
  <Row>
    <PageNavigation items={navItems} />
  </Row>
);

export default DataLakePageNavigation;
