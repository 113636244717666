import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_LAKE_API_ROUTES, DATA_LAKE_BACKEND_CONFIG_QUERY_KEYS, DATA_LAKE_CONFIG_QUERY_KEYS } from 'data-lake/Constants';
import type { DataLakeActiveBackend } from 'data-lake/Types';

const updateDataLakeBackendConfig = async (activeBackend: string) => (
  fetch('PUT', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.backendConfig.base().url), { active_backend: activeBackend })
);

const fetchDataLakeBackendConfig = async () => (
  fetch('GET', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.backendConfig.base().url))
);

const useUpdateDataLakeBackendConfig = (successHandler: () => void = undefined) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, error } = useMutation(updateDataLakeBackendConfig, {
    onSuccess: () => {
      UserNotification.success('Successfully updated Data Lake backend config');
      queryClient.invalidateQueries(DATA_LAKE_CONFIG_QUERY_KEYS);

      if (successHandler) {
        successHandler();
      }
    },
    onError: (err: Error) => {
      UserNotification.error(`Error updating Data Lake backend config: ${err.toString()}`, 'Unable to update Data Lake backend config.');
    },
  });

  return {
    mutateAsync,
    isLoading,
    error,
  };
};

const useDataLakeBackendConfig = () => {
  const { data, refetch, isInitialLoading, isSuccess, error } = useQuery<DataLakeActiveBackend, Error>(
    [...DATA_LAKE_BACKEND_CONFIG_QUERY_KEYS],
    () => fetchDataLakeBackendConfig(),
    {
      keepPreviousData: true,
    },
  );

  return ({
    data,
    refetch,
    isInitialLoading,
    isSuccess,
    error,
  });
};

export {
  useDataLakeBackendConfig,
  useUpdateDataLakeBackendConfig,
};
