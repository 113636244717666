import * as React from 'react';
import styled, { css } from 'styled-components';

import type { Stream } from 'stores/streams/StreamsStore';
import { Icon, Tooltip } from 'components/common';
import PreviewLakeLogsButton from 'data-lake/components/PreviewLakeLogsButton';

import useStreamDataLakeStatus from '../hooks/useStreamDataLakeStatus';

const Wrapper = styled.div(({ theme }) => css`
  color: ${theme.colors.variant.default};
  width: fit-content;
`);

type Props = {
  stream: Stream
}

const DataLakeCell = ({ stream }: Props) => {
  const { data: config, isFetching } = useStreamDataLakeStatus(stream.id);

  if (!stream.is_editable || isFetching) {
    return null;
  }

  const isDataLakeEnabled = config?.enabled || false;

  if (isDataLakeEnabled) {
    return (
      <PreviewLakeLogsButton streamId={stream.id} />
    );
  }

  return (
    <Tooltip withArrow position="right" label="Data Lake is disabled">
      <Wrapper>
        <Icon name="cancel" />
      </Wrapper>
    </Tooltip>
  );
};

export default DataLakeCell;
