import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import * as URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import ApiRoutes from 'routing/ApiRoutes';
import { defaultOnError } from 'util/conditional/onError';

import { DATA_LAKE_API_ROUTES } from '../Constants';
import type { DataLakeJob } from '../Types';

const refetchInterval = 3000;
const getQueryKey = (streamId?: string) => ['data-lake', 'jobs', streamId];

const fetchDataLakeJob = (streamId?: string): Promise<{
  in_progress: number,
  progress: Array<DataLakeJob>
}> => {
  const url = streamId ? DATA_LAKE_API_ROUTES.DataLakeAPIController.job.stream_jobs(streamId).url
    : DATA_LAKE_API_ROUTES.DataLakeAPIController.job.progress().url;

  return fetch('GET', qualifyUrl(url));
};

const cancelDataLakeJob = (id: string) => fetch('DELETE', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.job.cancel(id).url));
const acknowledgeDataLakeJob = (id: string) => fetch('DELETE', URLUtils.qualifyUrl(ApiRoutes.SystemJobsApiController.acknowledgeJob(id).url));

const useDataLakeJobs = (streamId?: string): {
  dataLakeData: {
    inProgressCount: number,
    jobs: Array<DataLakeJob>,
  },
  isError: boolean,
  refetchDataLake: () => void,
  isLoadingDataLake: boolean,
  onCancelDataLakeJob: (id: string) => Promise<void>,
  onAcknowledgeDataLakeJob: (id: string) => Promise<void>,
} => {
  const queryClient = useQueryClient();
  const { data, refetch: refetchDataLake, isLoading: isLoadingDataLake, isError } = useQuery(
    getQueryKey(streamId),
    () => defaultOnError(fetchDataLakeJob(streamId), 'Loading Data Lake jobs failed with status', 'Could not load Data Lake jobs'),
    {
      keepPreviousData: true,
      refetchInterval,
      notifyOnChangeProps: ['data', 'error'],
    },
  );
  const { mutateAsync: onCancelDataLakeJob } = useMutation(cancelDataLakeJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQueryKey());
    },
    onError: (errorThrown) => {
      UserNotification.error(`Canceling Data Lake job failed with status: ${errorThrown}`,
        'Could not cancel Data Lake jobs');
    },
  });
  const { mutateAsync: onAcknowledgeDataLakeJob } = useMutation(acknowledgeDataLakeJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQueryKey());
    },
    onError: (errorThrown) => {
      UserNotification.error(`Acknowledging Data Lake job status failed with status: ${errorThrown}`,
        'Could not acknowledge Data Lake jobs status');
    },
  });

  return ({
    dataLakeData: {
      inProgressCount: data?.in_progress,
      jobs: data?.progress,
    },
    isError,
    refetchDataLake,
    isLoadingDataLake,
    onCancelDataLakeJob,
    onAcknowledgeDataLakeJob,
  });
};

export default useDataLakeJobs;
