import * as React from 'react';
import { useState } from 'react';
import capitalize from 'lodash/capitalize';
import { useQueryClient } from '@tanstack/react-query';

import type { StreamDataLakeConfig } from 'data-lake/Types';
import useStreamDataLakeStatusMutation from 'data-lake/hooks/useStreamDataLakeStatusMutation';
import { ConfirmDialog, OverlayTrigger } from 'components/common';
import { Link } from 'components/common/router';
import Popover from 'components/common/Popover';
import {
  useDataLakeBackendConfig,
} from 'data-lake/data-lake-backend-config/hooks/useDataLakeBackendConfig';
import DestinationSwitch from 'components/streams/StreamDetails/routing-destination/DestinationSwitch';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import DataLakeRoutes from 'data-lake/Routes';

type Props = {
  streamId: string,
  config: StreamDataLakeConfig,
}

const StreamDataLakeEnableButton = ({ streamId, config }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { onToggleStreamDataLakeConfigStatus } = useStreamDataLakeStatusMutation();
  const { data, isSuccess } = useDataLakeBackendConfig();
  const isDataLakeEnabled = config?.enabled || false;
  const action = isDataLakeEnabled ? 'disable' : 'enable';
  const title = isDataLakeEnabled ? 'Enabled' : 'Disabled';
  const activeBackendExists = isSuccess && data.active_backend;
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const sendTelemetry = useSendTelemetry();

  const onModalClose = () => {
    setShowModal(false);
  };

  const onButtonClick = () => {
    setShowModal(true);

    sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.ENABLE_DATALAKE_CLICKED, {
      app_pathname: 'data-lake',
    });
  };

  const onConfirm = () => {
    onToggleStreamDataLakeConfigStatus({ streamIds: [streamId], enabled: !isDataLakeEnabled }).then(() => {
      setShowModal(false);
      queryClient.invalidateQueries(['data-lake', streamId]);
    });
  };

  const popover = (
    <Popover>
      No active Data Lake backend configured.
      Go to  <Link to={DataLakeRoutes.BACKEND}>Data Lake/Backend</Link> and set one up to use Data Lake.
    </Popover>
  );

  return (
    <>
      {showModal && (
        <ConfirmDialog show={showModal}
                       onConfirm={onConfirm}
                       onCancel={onModalClose}
                       title={`${capitalize(action)} Data Lake.`}>
          <>
            You are about to {`${action}`} <strong>Data Lake</strong> on this stream.
            <p>Are you sure you want to continue?</p>
          </>
        </ConfirmDialog>
      )}
      {activeBackendExists
        ? (
          <DestinationSwitch aria-label="Toggle data lake"
                             name="toggle-data-lake"
                             checked={isDataLakeEnabled}
                             onChange={onButtonClick}
                             disabled={!isPermitted(currentUser.permissions, 'archive:read')}
                             label={title} />
        )
        : (
          <OverlayTrigger placement="top"
                          trigger="click"
                          rootClose
                          overlay={popover}>
            <DestinationSwitch aria-label="Toggle data lake"
                               name="toggle-data-lake"
                               disabled
                               onChange={() => {}}
                               checked={false}
                               label={title} />
          </OverlayTrigger>
        )}
    </>
  );
};

export default StreamDataLakeEnableButton;
