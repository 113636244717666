import * as React from 'react';

import { Button, Col } from 'components/bootstrap';
import { Modal } from 'common/components';
import { useUploadSigmaRules } from 'security-app/hooks/useSigmaAPI';
import type { FormStateType } from 'security-app/components/common/FormikComponents';
import type { SigmaRuleZipValidationErrorAPIType } from 'security-app/hooks/api/sigmaAPI.types';

import { FlexRow, FlexCol, StyledProgressBar, StyledAlert } from './styled-components';
import { getBodyPayload } from './utils';
import FileList from './FileList';
import FileOptionsForm from './FileOptionsForm';
import type { FileFormType } from './types';

type ButtonsProps = {
  confirmDisabled: boolean,
  cancelDisabled: boolean,
  onCancel: () => void,
  uploadFiles: () => void,
};

const Buttons = ({ confirmDisabled, cancelDisabled, onCancel, uploadFiles }: ButtonsProps) => (
  <>
    <Button onClick={onCancel} disabled={cancelDisabled}>Cancel</Button>
    <Button bsStyle="success"
            onClick={uploadFiles}
            disabled={confirmDisabled}>
      Upload rules
    </Button>
  </>
);

type Props = {
  onClose: () => void,
};

function UploadRulesModal({ onClose }: Props) {
  const [files, setFiles] = React.useState<FileFormType[]>([]);
  const [selectedFile, setSelectedFile] = React.useState<FileFormType>(files[0]);
  const [uploadProgress, setUploadProgress] = React.useState<number | null>();
  const [validFormStateMap, setValidFormStateMap] = React.useState<{ [key: string]: boolean }>({});
  const { uploadSigmaRules, uploadingRules } = useUploadSigmaRules();

  const reportProgress = React.useCallback((percent: number) => {
    setUploadProgress(percent);
  }, []);

  const uploadFiles = React.useCallback(async () => {
    const bodyPayload = getBodyPayload(files);

    await uploadSigmaRules({
      fileForms: files,
      body: bodyPayload,
      progressCB: reportProgress,
    });

    const badFiles = files.filter((fileForm: FileFormType) => fileForm.ruleErrors.length > 0);

    if (badFiles.length > 0) {
      setFiles(badFiles);
      setSelectedFile(undefined);
      setUploadProgress(undefined);

      return;
    }

    onClose();
  }, [onClose, files, uploadSigmaRules, reportProgress]);

  const handleValidFormState = React.useCallback((formState: FormStateType) => {
    setValidFormStateMap((prevMap: { [key: string]: boolean }) => ({
      ...prevMap,
      [selectedFile.id]: formState.isValid,
    }));
  }, [selectedFile?.id]);

  const canImport = React.useMemo(() => (Object.values(validFormStateMap).every((value: boolean) => value)), [validFormStateMap]);

  return (
    <Modal show
           onClose={onClose}
           title="Upload Sigma Rules"
           maxWidth="900px"
           buttons={(
             <Buttons confirmDisabled={uploadingRules || !canImport || files.length < 1}
                      cancelDisabled={uploadingRules}
                      onCancel={onClose}
                      uploadFiles={uploadFiles} />
           )}>
      <FlexRow>
        <Col md={5}>
          <FileList files={files} setFiles={setFiles} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
        </Col>
        <FlexCol md={7}>
          {selectedFile && (
            <FileOptionsForm selectedFile={selectedFile}
                             setSelectedFile={setSelectedFile}
                             setFiles={setFiles}
                             formState={handleValidFormState} />
          )}
          {uploadProgress && (
            <StyledProgressBar bars={[
              { value: uploadProgress, bsStyle: 'info', animated: true, label: `Uploading ${uploadProgress}%` },
            ]} />
          )}
        </FlexCol>
      </FlexRow>
      {selectedFile?.ruleErrors.length > 0 && (
        <StyledAlert bsStyle="danger" aria-label="Rule format errors">
          {selectedFile.ruleErrors.map((error: string | SigmaRuleZipValidationErrorAPIType) => {
            if (typeof error === 'string') return <p key={error}>{error}</p>;

            return (
              <div key={error.file_name}>
                <strong>{error.file_name}:</strong>
                {error.errors.map((ruleError: string) => <p key={ruleError}>{ruleError}</p>)}
              </div>
            );
          })}
        </StyledAlert>
      )}
    </Modal>
  );
}

export default UploadRulesModal;
