import * as React from 'react';
import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import Store from 'logic/local-storage/Store';
import { DATA_LAKE_API_ROUTES, HIDE_LAKE_RETRIEVAL_HELP_TEXT_KEY } from 'data-lake/Constants';
import DataLakeActionModalForm from 'data-lake/components/DataLakeActionModalForm';
import { getDataLakeUriParams } from 'data-lake/logic/Utils';
import useUserDateTime from 'hooks/useUserDateTime';
import fetch from 'logic/rest/FetchProvider';
import { Alert } from 'components/bootstrap';
import { Link } from 'components/common/router';
import { handleFailures } from 'data-lake/data-lake-overview/actions/Utils';
import type { DataLakeActionValues } from 'data-lake/Types';
import DataLakeRoutes from 'data-lake/Routes';

type Props = {
  archiveStreamId: string,
  onToggleModal: () => void
};

const RetrievalArchiveModal = ({
  archiveStreamId,
  onToggleModal,
}: Props) => {
  const queryClient = useQueryClient();
  const [hideHelpText, setHideHelpText] = useState(Store.get(HIDE_LAKE_RETRIEVAL_HELP_TEXT_KEY) === 'true');
  const { userTimezone } = useUserDateTime();

  const hideRetrievalHelp = () => {
    Store.set(HIDE_LAKE_RETRIEVAL_HELP_TEXT_KEY, 'true');
    setHideHelpText(true);
  };

  const onRestoreSubmit = useCallback((values: DataLakeActionValues) => {
    const uriParams = getDataLakeUriParams(values, userTimezone);
    const { stream_ids, inclusion_type, field_filters } = values;

    fetch(
      'POST',
      qualifyUrl(`${DATA_LAKE_API_ROUTES.DataLakeAPIController.bulk_retrieval().url}?${uriParams}`),
      { stream_ids, inclusion_type, field_filters },
    ).then((response) => {
      handleFailures(response?.failures);
    }).catch((error) => {
      UserNotification.error(`An error occurred while retrieving data. ${error}`);
    }).finally(() => {
      queryClient.invalidateQueries(['data-lake']);
      onToggleModal();
    });
  }, [userTimezone, onToggleModal, queryClient]);

  return (
    <DataLakeActionModalForm modalTitle="Retrieving from Data Lake"
                             type="retrieval"
                             show
                             onHide={onToggleModal}
                             submitButtonText="Retrieve"
                             streamIds={[archiveStreamId]}
                             onSubmit={onRestoreSubmit}>
      <p>You are about to retrieve data from 1 Data Lake.</p>
      {!hideHelpText && (
        <Alert bsStyle="info" onDismiss={hideRetrievalHelp}>
          This will launch a retrieval operation to route messages from the Data Lake into the <b>Search Cluster</b>.<br />
          Retrieved messages that were not previously routed to the <b>Search Cluster </b> will count towards <b>Graylog License</b> usage.<br />
          Note that pulling messages from the Data Lake may take some time - progress may be tracked on
          the <Link to={DataLakeRoutes.ARCHIVE.LIST}><b>Data Lake</b></Link> page.
        </Alert>
      )}
    </DataLakeActionModalForm>
  );
};

export default RetrievalArchiveModal;
