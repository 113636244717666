import * as React from 'react';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';

import { Icon, ControlledTableList, PaginatedList, EntityList, Spinner } from 'components/common';
import SectionComponent from 'components/common/Section/SectionComponent';
import { Alert, Button } from 'components/bootstrap';
import { SearchForm } from 'security-app/components/common';
import IlluminatePackItem from 'illuminate/components/illuminate//IlluminatePackItem';
import IlluminateBulkActions from 'illuminate/components/illuminate/IlluminateBulkActions';
import IlluminateDescription from 'illuminate/components/illuminate/IlluminateDescription';
import { useGetInstallationStatus } from 'illuminate/hooks/useBundlesAPI';
import { useGetPacks, useUpdatePacks } from 'illuminate/hooks/usePacksAPI';
import type { Pack, InstallStatus } from 'illuminate/types';
import { BundleContext } from 'illuminate/context/BundleContext';
import { usePagination, useSetPagination } from 'common/contexts';
import IlluminateDeprecatedInstallationWarning from 'illuminate/components/illuminate/IlluminateDeprecatedInstallationWarning';
import type { LocalPagination } from 'common/contexts/Pagination';

const IlluminateRow = styled.div`
  display: table;
  width: 100%;
`;

const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const SearchButton = styled(Button)`
  border-radius: 4px;
`;

const DisplayTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .6rem;
  padding: 7px;
  background-color: ${({ theme }) => theme.colors.global.background};
  border-radius: 6px;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .6rem;
`;

export const useRefetchOnInstallationFinish = (onInstallFinished?: () => void) => {
  const queryClient = useQueryClient();
  const [installing, setInstalling] = React.useState<boolean>(false);
  const { installationStatus } = useGetInstallationStatus();

  const refetchPackData = React.useCallback(() => {
    queryClient.invalidateQueries(['all-bundles']);
    queryClient.invalidateQueries(['check-for-new-bundle']);
    queryClient.invalidateQueries(['hub-bundle']);
    queryClient.invalidateQueries(['illuminate-bundle-packs']);
    queryClient.invalidateQueries(['threat-coverage']);
  }, [queryClient]);

  React.useEffect(() => {
    if (installationStatus?.install_in_progress !== undefined) {
      setInstalling(installationStatus.install_in_progress);

      if (installing && !installationStatus.install_in_progress) {
        // When an install has just finished
        onInstallFinished?.();
        refetchPackData();
      }
    }
  }, [installing, installationStatus, refetchPackData, onInstallFinished]);

  return {
    installing,
  };
};

const InstallationStatus = ({ showInstallSuccessAlert, installationStatus }: {
  showInstallSuccessAlert: boolean,
  installationStatus: Partial<InstallStatus>,
}) => {
  if (!installationStatus?.previous_install || (installationStatus.previous_install.successful && !showInstallSuccessAlert)) {
    return null;
  }

  return (
    <Alert bsStyle={installationStatus.previous_install.successful ? 'success' : 'danger'}>
      <div>{installationStatus.previous_install.title}</div>
      <div>{installationStatus.previous_install.description}</div>
    </Alert>
  );
};

type Props = {
  showSearchForm?: boolean,
  showLoadingSpinner?: boolean,
  showInstallSuccessAlert?: boolean
  onInstallFinished?: (setLocalPagination: React.Dispatch<React.SetStateAction<LocalPagination>>) => void,
  includeDepPacks?: boolean,
  isGridView?: boolean,
  onChangeGridView?: (type: boolean) => void,
}

const IlluminatePacks = ({
  showSearchForm = true,
  onInstallFinished: onInstallFinishedProp = (setLocalPagination: React.Dispatch<React.SetStateAction<LocalPagination>>) => setLocalPagination((cur) => ({ ...cur, page: 1, query: '' })),
  showLoadingSpinner = false,
  showInstallSuccessAlert = true,
  includeDepPacks = false,
  isGridView = false,
  onChangeGridView = () => {},
}: Props) => {
  const localPagination = usePagination();
  const setLocalPagination = useSetPagination();
  const { updatingPacks } = useUpdatePacks();

  const { bundleVersion } = React.useContext(BundleContext);
  const { packs, pagination, loadingPacks } = useGetPacks({ ...localPagination, includeDeps: includeDepPacks }, bundleVersion);
  const { installationStatus, gettingStatus } = useGetInstallationStatus();

  const [focusedId, setFocusedId] = React.useState<string>(null);
  const { installing } = useRefetchOnInstallationFinish(() => onInstallFinishedProp?.(setLocalPagination));

  const onPageChange = (newPage: number, newPageSize: number) => {
    setFocusedId(null);
    setLocalPagination({ ...localPagination, page: newPage, perPage: newPageSize });
  };

  const setCurrentDescription = (id: string) => setFocusedId(focusedId === id ? null : id);

  const handleSearch = (newQuery: string = null) => {
    setFocusedId(null);
    setLocalPagination({ ...pagination, page: 1, query: newQuery });
  };

  const handleSearchReset = () => {
    setFocusedId(null);
    setLocalPagination({ ...pagination, page: 1, query: '' });
  };

  const packItems = packs.map((packItem: Pack) => (
    <IlluminatePackItem key={packItem.pack_id}
                        packItem={packItem}
                        focusedId={focusedId}
                        setCurrentDescription={setCurrentDescription} />
  ));

  if (gettingStatus || installing) {
    if (showLoadingSpinner) {
      return <Spinner />;
    }

    return null;
  }

  return (
    <SectionComponent title="">
      <IlluminateDeprecatedInstallationWarning />
      <InstallationStatus installationStatus={installationStatus} showInstallSuccessAlert={showInstallSuccessAlert} />

      <ControlledTableList>
        {showSearchForm && (
          <SearchRow>
            <SearchForm query={localPagination?.query || ''}
                        onSearch={handleSearch}
                        onReset={handleSearchReset}
                        placeholder="Search for packs"
                        roundedButtons />
            <DisplayTypeContainer>
              <TabsContainer>
                <SearchButton bsStyle={isGridView ? 'primary' : 'default'}
                              bsSize="small"
                              onClick={() => onChangeGridView(true)}>
                  <Icon name="grid_view" />
                </SearchButton>
                <SearchButton bsStyle={!isGridView ? 'primary' : 'default'}
                              bsSize="small"
                              onClick={() => onChangeGridView(false)}>
                  <Icon name="view_list" />
                </SearchButton>
              </TabsContainer>
            </DisplayTypeContainer>
          </SearchRow>
        )}
        <ControlledTableList.Header>
          <IlluminateBulkActions packs={loadingPacks ? [] : packs} />
        </ControlledTableList.Header>
        {loadingPacks || updatingPacks || installing ? (
          <Spinner text="Loading Illuminate Packs" delay={0} />
        ) : (
          <PaginatedList activePage={localPagination.page}
                         pageSize={localPagination.perPage}
                         onChange={onPageChange}
                         totalItems={pagination.total}
                         showPageSizeSelect={false}
                         useQueryParameter={false}>
            <IlluminateRow>
              <EntityList items={packItems}
                          noItemsText="There are no packs present/matching the filter!" />
              {focusedId && <IlluminateDescription activePack={packs.find((pack: Pack) => focusedId === pack.pack_id)} packs={packs} />}
            </IlluminateRow>
          </PaginatedList>
        )}
      </ControlledTableList>
    </SectionComponent>
  );
};

export default IlluminatePacks;
