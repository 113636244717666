import { useMutation, useQueryClient } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import {
  DATA_LAKE_API_ROUTES, DATA_LAKE_CONFIG_QUERY_KEYS,
} from 'data-lake/Constants';
import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import type { Backend, BackendCreate } from 'archive/types';

const createDataLakeBackend = async (backend: BackendCreate) => (
  fetch('POST', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.backend.base().url), { ...backend })
);

const updateDataLakeBackend = async (backend: Backend) => (
  fetch('PUT', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.backend.update(backend.id).url), { ...backend })
);
const deleteDataLakeBackend = async (id: string) => (
  fetch('DELETE', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.backend.delete(id).url))
);

const useCreateDataLakeBackend = (successHandler: () => void = undefined) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, error } = useMutation(createDataLakeBackend, {
    onSuccess: () => {
      UserNotification.success('Successfully created Data Lake backend.');
      queryClient.invalidateQueries(DATA_LAKE_CONFIG_QUERY_KEYS);

      if (successHandler) {
        successHandler();
      }
    },
    onError: (err: Error) => {
      UserNotification.error(`Error creating Data Lake backend: ${err.toString()}`, 'Unable to create Data Lake backend.');
    },
  });

  return {
    mutateAsync,
    isLoading,
    error,
  };
};

const useUpdateDataLakeBackend = (successHandler: () => void = undefined) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, error } = useMutation(updateDataLakeBackend, {
    onSuccess: () => {
      UserNotification.success('Successfully updated Data Lake backend');
      queryClient.invalidateQueries(DATA_LAKE_CONFIG_QUERY_KEYS);

      if (successHandler) {
        successHandler();
      }
    },
    onError: (err: Error) => {
      UserNotification.error(`Error updating Data Lake backend: ${err.toString()}`, 'Unable to update Data Lake backend.');
    },
  });

  return {
    mutateAsync,
    isLoading,
    error,
  };
};

const useDeleteDataLakeBackend = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(deleteDataLakeBackend, {
    onSuccess: () => {
      UserNotification.success('Successfully deleted Data Lake backend');
      queryClient.invalidateQueries(DATA_LAKE_CONFIG_QUERY_KEYS);
    },
    onError: (err: Error) => {
      UserNotification.error(`Error deleting Data Lake backend: ${err.toString()}`, 'Unable to delete Data Lake backend.');
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};

export {
  useCreateDataLakeBackend,
  useUpdateDataLakeBackend,
  useDeleteDataLakeBackend,
  deleteDataLakeBackend,
};
