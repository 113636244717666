import * as React from 'react';
import moment from 'moment';

import type { DataLake } from '../../Types';

type Props = {
  dataLake: DataLake,
}

const DaysOfData = ({ dataLake }: Props) => {
  const days = (!dataLake.timestamp_from || !dataLake.timestamp_to)
    ? 0 : moment(dataLake.timestamp_to).diff(dataLake.timestamp_from, 'days');

  return (
    <>{days}</>
  );
};

export default DaysOfData;
