import * as React from 'react';
import styled from 'styled-components';

import { MarkdownPreview } from 'components/common/MarkdownEditor';
import { Collapsible } from 'common/components';
import { DataTable, DataWell } from 'security-app/components/common';
import { Row, Col } from 'security-app/components/common/FormBaseStyles';
import { useStreamOptions, useNotificationOptions } from 'security-app/components/common/FormikComponents';
import type { RuleOptionsType } from 'security-app/hooks/api/sigmaAPI.types';
import type { OptionsType } from 'security-app/components/common/FormikComponents';
import type { SearchFilter } from 'components/event-definitions/event-definitions-types';

const FilterTitle = styled.small<{ $disabled: boolean }>`
  text-decoration: ${({ $disabled }) => ($disabled ? 'line-through' : 'none')};
`;

const Description = styled.div`
  white-space: pre-wrap;
`;

type Props = {
  ruleOptions: RuleOptionsType,
};

function RuleOptionsReadOnly({ ruleOptions }: Props) {
  const { streamOptions, loadingStreams } = useStreamOptions();
  const { notificationOptions, loadingNotifications } = useNotificationOptions();

  const streamNames = React.useMemo(() => (
    loadingStreams
      ? []
      : streamOptions
        .filter((streamOption: OptionsType) => ruleOptions.streams.includes(streamOption.value))
        .map((streamOption: OptionsType) => streamOption.label)
  ), [streamOptions, loadingStreams, ruleOptions.streams]);

  const notificationNames = React.useMemo(() => (
    loadingNotifications
      ? []
      : notificationOptions
        .filter((notiOption: OptionsType) => ruleOptions.notifications.includes(notiOption.value))
        .map((notiOption: OptionsType) => notiOption.label)
  ), [notificationOptions, loadingNotifications, ruleOptions.notifications]);

  return (
    <Col $width="100%">
      <DataTable condensed striped role="table" aria-label="Rule options details">
        <tbody>
          <DataTable.Row>
            <DataTable.Item $label>Streams:</DataTable.Item>
            <DataTable.Item>{streamNames.join(', ') || <i>No streams</i>}</DataTable.Item>
          </DataTable.Row>
          {ruleOptions.stream_categories.length > 0 && (
            <DataTable.Row>
              <DataTable.Item $label>Stream categories:</DataTable.Item>
              <DataTable.Item>{ruleOptions.stream_categories.join(', ')}</DataTable.Item>
            </DataTable.Row>
          )}
          <DataTable.Row>
            <DataTable.Item $label>Notifications:</DataTable.Item>
            <DataTable.Item>{notificationNames.join(', ') || <i>No notifications</i>}</DataTable.Item>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Item $label>Search within:</DataTable.Item>
            <DataTable.Item>
              <Row $width="100%" $gap="0.5rem">
                <span>{ruleOptions.search_within}</span>
                <span>{ruleOptions.search_within_unit}</span>
              </Row>
            </DataTable.Item>
          </DataTable.Row>
          <DataTable.Row>
            {ruleOptions.use_cron_scheduling
              ? (
                <>
                  <DataTable.Item $label>Cron expression:</DataTable.Item>
                  <DataTable.Item>
                    <Row $width="100%" $gap="0.5rem">
                      <span>{ruleOptions.cron_expression}</span>
                      <span>{ruleOptions.cron_timezone}</span>
                    </Row>
                  </DataTable.Item>
                </>
              ) : (
                <>
                  <DataTable.Item $label>Execute every:</DataTable.Item>
                  <DataTable.Item>
                    <Row $width="100%" $gap="0.5rem">
                      <span>{ruleOptions.execute_every}</span>
                      <span>{ruleOptions.execute_every_unit}</span>
                    </Row>
                  </DataTable.Item>
                </>
              )}
          </DataTable.Row>
        </tbody>
      </DataTable>
      <Col $width="100%" $gap="0.3rem" role="list" aria-label="Rule search filters details list">
        <Row>Filters: {ruleOptions.filters.length < 1 && <i>No Filters</i>}</Row>
        {ruleOptions.filters.map((filter: SearchFilter) => (
          <Collapsible key={`filter-${filter.id}`}
                       title={(
                         <Row $width="100%" $align="center">
                           {filter.negation && <small>Not</small>}
                           <FilterTitle $disabled={filter.disabled}>{filter.title || <i>No title set</i>}</FilterTitle>
                           <small>({filter.type})</small>
                         </Row>
                        )}>
            <DataWell>
              <Col $width="100%" $align="stretch">
                <code>{filter.queryString}</code>
                <Description>{filter.description || <i>No description</i>}</Description>
              </Col>
            </DataWell>
          </Collapsible>
        ))}
      </Col>
      <Col $width="100%" $gap="0.3rem" role="contentinfo" aria-label="Rule remediation steps">
        <Row $width="100%">Remediation steps: {!ruleOptions.remediation_steps && <i>No remediation steps</i>}</Row>
        {ruleOptions.remediation_steps && (
          <Row $fullWidth $width="100%">
            <MarkdownPreview show
                             value={ruleOptions.remediation_steps}
                             height={150}
                             withFullView
                             noBorder
                             noBackground />
          </Row>
        )}
      </Col>
    </Col>
  );
}

export default RuleOptionsReadOnly;
