import __request__ from 'routing/request';
interface UpdateSecurityEventRequest {
    readonly owner: string;
    readonly notes: string;
    readonly status: 'NEW' | 'INVESTIGATING' | 'FALSE_POSITIVE' | 'CLOSED';
}
interface SetOwnerForEvents {
    readonly owner_id: string;
    readonly event_ids: string[];
}
interface UsersResponse {
    readonly users: SimpleUserDto[];
}
interface GetSecurityEventsByIdsRequest {
    readonly event_ids: string[];
}
interface SecurityEventFilterOptionsRequest {
    readonly timerange: number;
    readonly fields: string[];
}
interface PaginatedResponse_SecurityEvent {
    readonly [_key: string]: Object;
}
interface EvidenceCounts {
    readonly searches: number;
    readonly logs: number;
    readonly dashboards: number;
    readonly events: number;
}
interface EventReplayInfo {
    readonly timerange_end: string;
    readonly query: string;
    readonly streams: string[];
    readonly filters: UsedSearchFilter[];
    readonly timerange_start: string;
}
interface InvestigationListItem {
    readonly archived: boolean;
    readonly updated_at: string;
    readonly name: string;
    readonly created_at: string;
    readonly evidence_counts: EvidenceCounts;
    readonly id: string;
    readonly priority: number;
    readonly status: string;
    readonly assigned_to: string;
}
interface SetStatusForEvents {
    readonly event_ids: string[];
    readonly status: 'NEW' | 'INVESTIGATING' | 'FALSE_POSITIVE' | 'CLOSED';
}
type SecurityEventResponseArray = SecurityEventResponse[];
interface SecurityEventFilterOptions {
    readonly owner: string[];
    readonly event_definition_id: string[];
    readonly id: string;
    readonly status: ('NEW' | 'INVESTIGATING' | 'FALSE_POSITIVE' | 'CLOSED')[];
}
interface SecurityEventsSearchRequest {
    readonly filter: SecurityEventsFilter;
    readonly sort_direction: 'asc' | 'desc';
    readonly per_page: number;
    readonly timerange: TimeRange;
    readonly timezone: string;
    readonly query: string;
    readonly sort_by: string;
    readonly page: number;
}
interface AddNotesForEvents {
    readonly notes: string;
    readonly event_ids: string[];
}
interface SecurityEventsFilter {
    readonly alerts: 'include' | 'exclude' | 'only';
    readonly owner: string[];
    readonly event_definitions: string[];
    readonly priority: string[];
    readonly status: ('NEW' | 'INVESTIGATING' | 'FALSE_POSITIVE' | 'CLOSED')[];
}
interface SimpleUserDto {
    readonly full_name: string;
    readonly id: string;
}
interface SecurityEvent {
    readonly owner: string;
    readonly opened_at: string;
    readonly closed_at: string;
    readonly event_id: string;
    readonly notes: string;
    readonly updated_at: string;
    readonly id: string;
    readonly event: EventDto;
    readonly recorded_metrics: string[];
    readonly is_security_event: boolean;
    readonly status: 'NEW' | 'INVESTIGATING' | 'FALSE_POSITIVE' | 'CLOSED';
}
interface UsedSearchFilter {
}
interface TeamsResponse {
    readonly teams: SimpleTeamDto[];
}
interface EventDto {
    readonly timerange_end: string;
    readonly timestamp_processing: string;
    readonly origin_context: string;
    readonly scores: {
        readonly [_key: string]: number;
    };
    readonly replay_info: EventReplayInfo;
    readonly streams: string[];
    readonly source_streams: string[];
    readonly source: string;
    readonly priority: number;
    readonly message: string;
    readonly associated_assets: string[];
    readonly group_by_fields: {
        readonly [_key: string]: string;
    };
    readonly key_tuple: string[];
    readonly alert: boolean;
    readonly event_definition_type: string;
    readonly event_definition_id: string;
    readonly id: string;
    readonly fields: {
        readonly [_key: string]: string;
    };
    readonly key: string;
    readonly timerange_start: string;
    readonly timestamp: string;
}
interface CreateSecurityEventRequest {
    readonly owner: string;
    readonly event_id: string;
    readonly notes: string;
    readonly status: 'NEW' | 'INVESTIGATING' | 'FALSE_POSITIVE' | 'CLOSED';
}
interface SimpleTeamDto {
    readonly name: string;
    readonly id: string;
}
interface SecurityEventResponse {
    readonly owner: string;
    readonly event_id: string;
    readonly notes: string;
    readonly associated_investigations: InvestigationListItem[];
    readonly updated_at: string;
    readonly event: EventDto;
    readonly status: 'NEW' | 'INVESTIGATING' | 'FALSE_POSITIVE' | 'CLOSED';
}
interface TimeRange {
    readonly type: string;
}
/**
 * Get a paginated list of security events
 * @param direction The sort direction
 */
export function listEvents(page: number = 1, per_page: number = 15, timerange?: number, alerts?: string, query?: string, filters?: string, openSearchFilters?: string, direction: 'asc' | 'desc' = 'asc', user_timezone: string = 'UTC'): Promise<PaginatedResponse_SecurityEvent> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/security_events', null, { 'page': page, 'per_page': per_page, 'timerange': timerange, 'alerts': alerts, 'query': query, 'filters': filters, 'openSearchFilters': openSearchFilters, 'direction': direction, 'user_timezone': user_timezone }, {
        'Accept': ['application/json']
    });
}
/**
 * Open a new security event
 */
export function openEvent(JSONBody?: CreateSecurityEventRequest): Promise<SecurityEvent> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/security_events', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get Security Events by IDs.
 */
export function getSecurityEventsByIds(ids: GetSecurityEventsByIdsRequest): Promise<SecurityEventResponseArray> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/security_events/byIds', ids, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all possible values for a list of fields
 */
export function getFilterOptions(JSONBody?: SecurityEventFilterOptionsRequest): Promise<SecurityEventFilterOptions> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/security_events/filter_options', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add notes for multiple Security Events.
 */
export function addNotesForEvents(request: AddNotesForEvents): Promise<void> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/security_events/notes', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Set owner for multiple Security Events.
 */
export function setOwnerForEvents(ids: SetOwnerForEvents): Promise<void> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/security_events/owner', ids, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Search security events
 */
export function search(JSONbody?: SecurityEventsSearchRequest): Promise<PaginatedResponse_SecurityEvent> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/security_events/search', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Set status for multiple Security Events.
 */
export function setStatusForEvents(request: SetStatusForEvents): Promise<void> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/security_events/status', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve list of teams available to be assigned to security events
 */
export function getInvestigationTeams(): Promise<TeamsResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/security_events/teams', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve list of users available to be assigned to security events
 */
export function getInvestigationUsers(): Promise<UsersResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/security_events/users', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a Security Event by ID.
 */
export function getSecurityEvent(eventId: string): Promise<SecurityEventResponse> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/security_events/${eventId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Upsert a security event.Automatically creates a security event for the specified event id if one does not exist yet.
 */
export function upsertEvent(eventId: string, JSONBody?: UpdateSecurityEventRequest): Promise<SecurityEvent> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/security_events/${eventId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
