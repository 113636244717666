import * as React from 'react';

import RuleOptionsForm from 'security-app/components/SigmaRules/RuleOptionsForm';
import type { RuleOptionsType } from 'security-app/hooks/api/sigmaAPI.types';
import type { FormStateType } from 'security-app/components/common/FormikComponents';

import { FormContainer, FileNameTitle, StyledAlert } from '../styled-components';
import type { FileFormType } from '../types';

type Props = {
  selectedFile: FileFormType,
  setSelectedFile: React.Dispatch<React.SetStateAction<FileFormType>>,
  setFiles: React.Dispatch<React.SetStateAction<Array<FileFormType>>>,
  formState: (formState: FormStateType) => void,
};

function FileOptionsForm({ selectedFile, setSelectedFile, setFiles, formState }: Props) {
  const onOptionsChange = React.useCallback((newOptions: RuleOptionsType) => {
    setSelectedFile((prevFile: FileFormType) => ({ ...prevFile, ...newOptions }));

    setFiles((prevFiles: Array<FileFormType>) => (prevFiles.map((prevFile: FileFormType) => {
      if (prevFile.id === selectedFile.id) return { ...prevFile, ...newOptions };

      return prevFile;
    })));
  }, [setSelectedFile, setFiles, selectedFile.id]);

  return (
    <FormContainer>
      <FileNameTitle>{selectedFile.file.name}</FileNameTitle>
      <RuleOptionsForm formId={selectedFile.id}
                       initialValues={selectedFile}
                       onSubmit={onOptionsChange}
                       formState={formState}
                       autoSubmit />
      {(selectedFile.file.type === 'application/zip' && selectedFile.ruleErrors.length === 0) && (
        <StyledAlert bsStyle="info" aria-label="ZIP files info message">
          <p>All rules included in the ZIP file will share the same settings</p>
        </StyledAlert>
      )}
    </FormContainer>
  );
}

export default FileOptionsForm;
