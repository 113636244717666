import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import {
  DATA_LAKE_API_ROUTES,
  DATA_LAKE_BACKEND_QUERY_KEYS,
} from 'data-lake/Constants';
import type { Backend } from 'archive/types';
import { defaultOnError } from 'util/conditional/onError';

const INITIAL_DATA = [];

type Options = {
  enabled: boolean,
}

const refetchInterval = 5000;

const fetchConfig = () => fetch('GET', qualifyUrl(DATA_LAKE_API_ROUTES.DataLakeAPIController.backend.base().url));

const useDataLakeBackends = ({ enabled }: Options = { enabled: true }): {
  data: Array<Backend>,
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { data, refetch, isInitialLoading } = useQuery(
    [...DATA_LAKE_BACKEND_QUERY_KEYS],
    () => defaultOnError(fetchConfig(), 'Loading Data Lake backends failed with status', 'Could not load Data Lake backends.'),
    {
      keepPreviousData: true,
      refetchInterval,
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data: data ?? INITIAL_DATA,
    refetch,
    isInitialLoading,
  });
};

export default useDataLakeBackends;
