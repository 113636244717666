import * as React from 'react';
import { useState } from 'react';
import type { SyntheticEvent } from 'react';
import { Formik, Form } from 'formik';

import { Button, Modal } from 'components/bootstrap';
import { FormikInput, Icon, ModalSubmit } from 'components/common';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPermitted } from 'util/PermissionsMixin';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useDataLakeRetention from 'data-lake/hooks/useDataLakeRetention';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import type { StreamDataLakeConfig } from 'data-lake/Types';
import { formatRetentionLabel, validateRetention } from 'data-lake/logic/Utils';

type FormValues = {retentionTime: string};
type Props = {
  title: string,
  config: StreamDataLakeConfig,
};

const DataLakeRetentionForm = ({ title, config }: Props) => {
  const currentUser = useCurrentUser();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { onUpdateDataLakeRetentionMutation } = useDataLakeRetention();
  const sendTelemetry = useSendTelemetry();

  const openModal = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const validate = (values: FormValues) => {
    let errors = {};

    if (!values.retentionTime) {
      errors = { retentionTime: 'Invalid duration.' };
    }

    return errors;
  };

  const onSubmit = ({ retentionTime }: FormValues) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.RETENTION_CONFIG_SAVED, {
      app_pathname: 'streams',
    });

    const retention_time = retentionTime === '0' ? null : retentionTime;

    onUpdateDataLakeRetentionMutation({ streamId: config.stream_id, retentionTime: retention_time }).then(() => setShowModal(false));
  };

  return (
    <>
      <Button disabled={!isPermitted(currentUser.permissions, 'datawarehouse:edit')}
              bsSize="sm"
              onClick={openModal}
              title="Configure Retention">
        <Icon name="settings" /> Configure Retention
      </Button>
      {showModal && (
      <Modal show={showModal}
             onHide={onCloseModal}>
        <Formik<FormValues> initialValues={{ retentionTime: config.retention_time }}
                            onSubmit={onSubmit}
                            validate={validate}>
          {({ values, isSubmitting, isValidating }) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{`Data Lake Retention for ${title}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormikInput type="text"
                             name="retentionTime"
                             id="retentionTime"
                             placeholder="P1D"
                             label="Maximum number of days in the Data Lake"
                             help={<>Define how many days to keep archives before deleting them. When 0 P0D is set, automatic deletion is disabled.</>}
                             addonAfter={formatRetentionLabel(values.retentionTime)}
                             validate={validateRetention}
                             required />
              </Modal.Body>
              <Modal.Footer>
                <ModalSubmit submitButtonText="Update"
                             submitLoadingText="Updating retention"
                             onCancel={onCloseModal}
                             disabledSubmit={isValidating}
                             isSubmitting={isSubmitting} />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      )}
    </>
  );
};

export default DataLakeRetentionForm;
