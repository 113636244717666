import { qualifyUrls } from 'routing/Routes';

const MimecastRoutes = {
  INTEGRATIONS: {
    Mimecast: {
      ACTIVITYAPI: {
        index: '/integrations/mimecast',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    Mimecast: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/mimecast/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/mimecast/check_credentials',

    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    Mimecast: {
      GRAYLOG_Mimecast_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_mimecast_ActivityLog_Input.html#graylog-mimecast-activitylog-input',
    },
  },
};

export default {
  ...qualifyUrls(MimecastRoutes),
  unqualified: MimecastRoutes,
};

export { DocsRoutes, ApiRoutes };
