import * as React from 'react';

import { GLCheckbox } from 'common/components';
import { useSelectedRows, useSelectedRowsDispatch } from 'common/contexts';
import type { GitRepoContent, GitRepoAPIModel } from 'security-app/hooks/api/sigmaAPI.types';

import { Dir, File } from '../styledComponents';
import RuleInfoTooltip from '../RuleTooltip';

type Props = {
  item: GitRepoContent & GitRepoAPIModel,
  repoPath: string,
  setRepo: (newRepo: GitRepoAPIModel) => void,
  setRepoPath: (newPath: string) => void,
};

function RuleItem({ item, repoPath, setRepo, setRepoPath }: Props) {
  const selectedRules = useSelectedRows();
  const selectedRulesDispatch = useSelectedRowsDispatch();

  const loadRepoRules = () => {
    setRepo(item);
    setRepoPath('');
  };

  const loadDirectory = () => setRepoPath(`${repoPath}/${item.name}`);

  const onSelect = (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      selectedRulesDispatch({ type: 'add', payload: [item] });
    } else {
      selectedRulesDispatch({ type: 'remove', payload: [item] });
    }
  };

  const isSelected = (rule: GitRepoContent) => (
    !!selectedRules.find((sRule: GitRepoContent) => rule.id === sRule.id)
  );

  if (!item.type) {
    return (
      <Dir onClick={loadRepoRules} data-testid="repo">
        {item.name}
      </Dir>
    );
  }

  if (item.type === 'dir') {
    return (
      <Dir title={item.name} onClick={loadDirectory} data-testid="directory">
        {item.name}/
      </Dir>
    );
  }

  return (
    <File htmlFor={`import-repo-file-${item.id}`} data-testid="file">
      <GLCheckbox id={`import-repo-file-${item.id}`}
                  checked={isSelected(item)}
                  onChange={onSelect}
                  data-testid="select-file" />
      <RuleInfoTooltip rule={item} />
      {item.title}
    </File>
  );
}

export default RuleItem;
