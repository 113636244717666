import React, { useCallback, useState } from 'react';

import { Button } from 'components/bootstrap';
import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import type { DataLake } from 'data-lake/Types';
import { MoreActions } from 'components/common/EntityDataTable';
import RetrievalArchiveButton from 'data-lake/data-lake-overview/actions/RetrievalArchiveButton';
import RetrievalArchiveModal from 'data-lake/data-lake-overview/actions/RetrievalArchiveModal';
import DeleteArchiveModal from 'data-lake/data-lake-overview/actions/DeleteArchiveModal';
import DeleteArchiveButton from 'data-lake/data-lake-overview/actions/DeleteArchiveButton';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';
import { LICENSE_SUBJECTS } from 'license/constants';
import { IfPermitted, Spinner } from 'components/common';
import PreviewLakeLogsButton from 'data-lake/components/PreviewLakeLogsButton';

const EntityActions = ({ archiveStreamId }: { archiveStreamId: string }) => {
  const { data: { valid: isValidLicense }, isInitialLoading: isLoadingLicense } = useLicenseValidityForSubject(LICENSE_SUBJECTS.enterprise);
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleRetrievalModal = () => setShowRetrievalModal((cur) => !cur);
  const toggleDeleteModal = () => setShowDeleteModal((cur) => !cur);

  if (isLoadingLicense) {
    return <Spinner />;
  }

  return (
    <>
      {isValidLicense && (
        <>
          <PreviewLakeLogsButton streamId={archiveStreamId} />
          <IfPermitted permissions={['data_warehouse_archive:restore']}>
            <RetrievalArchiveButton onClick={toggleRetrievalModal} buttonType="button" />
          </IfPermitted>
        </>
      )}
      <LinkContainer to={`${Routes.stream_view(archiveStreamId)}?segment=destinations`}>
        <Button bsStyle="info" bsSize="xsmall">Data Routing</Button>
      </LinkContainer>
      {isValidLicense && (
        <IfPermitted permissions={['data_warehouse_archive:delete']}>
          <MoreActions>
            <DeleteArchiveButton onClick={toggleDeleteModal} />
          </MoreActions>
        </IfPermitted>
      )}

      {showRetrievalModal && (
        <RetrievalArchiveModal archiveStreamId={archiveStreamId}
                               onToggleModal={toggleRetrievalModal} />
      )}
      {showDeleteModal && (
        <DeleteArchiveModal archiveStreamId={archiveStreamId}
                            onToggleModal={toggleDeleteModal} />
      )}
    </>
  );
};

const useTableElements = () => {
  const entityActions = useCallback((dataLake: DataLake) => (
    <EntityActions archiveStreamId={dataLake.stream_id} />
  ), []);

  return {
    entityActions,
  };
};

export default useTableElements;
