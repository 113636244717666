import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedMimecastApp from './EmbeddedMimecastApp';

const MimecastApp = () => (
  <>
    <PageHeader title="Mimecast Integrations">
      <span>This feature retrieves log records from Mimecast.</span>
    </PageHeader>
    <EmbeddedMimecastApp />
  </>
);

export default MimecastApp;
