import React, { useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';

import {
  SearchBarContainer,
  TimeRangeRow,
  SEARCH_BAR_GAP,
  SearchQueryRow,
  SearchInputAndValidationContainer,
  SearchButtonAndQuery,
} from 'views/components/searchbar/SearchBarLayout';
import TimeRangeFilter from 'views/components/searchbar/time-range-filter';
import type { TimeRange } from 'views/logic/queries/Query';
import StreamFilter from 'data-lake/search/StreamFilter';
import ViewsRefreshControls from 'views/components/searchbar/ViewsRefreshControls';
import SearchButton from 'views/components/searchbar/SearchButton';
import { Button } from 'components/bootstrap';
import { DEFAULT_TIMERANGE } from 'views/Constants';
import SearchBarFieldsFilter from 'data-lake/search/SearchBarFieldsFilter';
import type { FieldFilters, FieldsOperator } from 'data-lake/Types';
import { normalizeFromSearchBarForBackend } from 'views/logic/queries/NormalizeTimeRange';
import useUserDateTime from 'hooks/useUserDateTime';

export const WAHREHOUSE_PREVIEW_FORM_ID = 'warehouse-preview-form';

export type FormValues = {
  timerange: TimeRange,
  stream: string | undefined,
  fields: { fieldFilters: Array<FieldFilters>, operator: FieldsOperator },
}

const StreamsAndRefresh = styled.div`
  display: flex;
  gap: ${SEARCH_BAR_GAP};
  flex: 1.5;
`;

const validate = (formValues: FormValues) => {
  const errors = {};

  if (!formValues.stream) {
    return ({ ...errors, stream: 'Stream is required' });
  }

  return errors;
};

type Props = {
  onSubmit: (formValues: FormValues) => void,
  initialValues: FormValues,
  isLoading: boolean,
  onReset: () => void,
}

const SearchBar = ({ onSubmit: onSubmitProp, onReset, initialValues, isLoading }: Props) => {
  const { userTimezone } = useUserDateTime();
  const onSubmit = useCallback((formValues: FormValues) => {
    onSubmitProp({
      ...formValues,
      timerange: normalizeFromSearchBarForBackend(formValues.timerange, userTimezone),
    });
  }, [onSubmitProp, userTimezone]);

  return (
    <Formik<FormValues> initialValues={initialValues}
                        enableReinitialize
                        onSubmit={onSubmit}
                        validate={validate}>
      {({
        dirty,
        isSubmitting,
        isValid,
        isValidating,
        resetForm,
      }) => {
        const disableSearchSubmit = isSubmitting || isValidating || !isValid;

        const resetSearch = () => {
          resetForm({
            values: {
              timerange: DEFAULT_TIMERANGE,
              stream: undefined,
              fields: undefined,
            },
          });

          onReset();
        };

        return (
          <Form id={WAHREHOUSE_PREVIEW_FORM_ID}>
            <SearchBarContainer>
              <TimeRangeRow>
                <Field name="timerange">
                  {({ field: { name, value, onChange }, meta: { error } }) => (
                    <TimeRangeFilter limitDuration={0}
                                     onChange={(nextTimeRange) => onChange({
                                       target: { value: nextTimeRange, name },
                                     })}
                                     value={value}
                                     hasErrorOnMount={!!error} />
                  )}
                </Field>
                <StreamsAndRefresh>
                  <StreamFilter />
                  <ViewsRefreshControls />
                </StreamsAndRefresh>
              </TimeRangeRow>
              <SearchQueryRow>
                <SearchButtonAndQuery>
                  <SearchButton disabled={disableSearchSubmit}
                                dirty={dirty}
                                displaySpinner={isSubmitting || isLoading} />
                  <SearchInputAndValidationContainer>
                    <SearchBarFieldsFilter />
                  </SearchInputAndValidationContainer>
                  <Button onClick={resetSearch} title="Reset filters">Reset</Button>
                </SearchButtonAndQuery>
              </SearchQueryRow>
            </SearchBarContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SearchBar;
