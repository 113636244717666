import * as React from 'react';
import { useFormikContext } from 'formik';

import type { FormStateType } from './types';

type Props = {
  formState: (value: FormStateType) => void,
};

function SubmitOnValueChange({ formState }: Props) {
  const { submitForm, dirty, touched, isValid, values } = useFormikContext();

  React.useEffect(() => { submitForm(); }, [submitForm, values]);

  React.useEffect(() => {
    const formTouched = Object.values(touched).some((fieldTouched: boolean) => fieldTouched);

    formState({
      isValid,
      touched: formTouched,
      dirty,
    });
  }, [isValid, dirty, touched, formState]);

  return null;
}

export default SubmitOnValueChange;
