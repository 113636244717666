import { useQuery } from '@tanstack/react-query';
import URI from 'urijs';

import { getDataLakeUriParams } from 'data-lake/logic/Utils';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_LAKE_API_ROUTES, DATA_LAKE_INCLUSION_TYPE } from 'data-lake/Constants';
import useUserDateTime from 'hooks/useUserDateTime';
import type FetchError from 'logic/errors/FetchError';
import type { EstimateParams, EstimateType } from 'data-lake/Types';
import { defaultOnError } from 'util/conditional/onError';

type Options = {
  enabled: boolean,
}

const fetchEstimate = (params: EstimateParams, userTimezone: string) => {
  const { streamIds, from, to, inclusionType = DATA_LAKE_INCLUSION_TYPE, fieldFilters } = params;

  if (from === '' || to === '' || streamIds.length === 0) {
    return null;
  }

  const uri = new URI(DATA_LAKE_API_ROUTES.DataLakeAPIController.estimate().url)
    .addSearch({ stream_ids: streamIds, inclusion_type: inclusionType })
    .toString();

  return fetch('POST', qualifyUrl(`${uri}&${getDataLakeUriParams({ from, to }, userTimezone)}`), [...fieldFilters]);
};

const useDataLakeEstimate = (params: EstimateParams, { enabled }: Options = { enabled: true }): {
  data: EstimateType,
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { userTimezone } = useUserDateTime();
  const { data, refetch, isInitialLoading } = useQuery<EstimateType, FetchError>(
    ['data-lake', 'estimate', params],
    () => defaultOnError(fetchEstimate(params, userTimezone), 'Loading Data Lake action estimate failed with status', 'Could not load Data Lake estimate.'),
    {
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data,
    refetch,
    isInitialLoading,
  });
};

export default useDataLakeEstimate;
