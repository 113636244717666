import * as React from 'react';
import styled from 'styled-components';

import { IconButton } from 'components/common';
import { Alert } from 'components/bootstrap';
import { Row, Col } from 'security-app/components/common/FormBaseStyles';

import type { ImportErrorsType } from './types';

const StyledAlert = styled(Alert)`
  & .mantine-Alert-label {
    width: 100%;
  }
`;

type Props = {
  importErrors: string[],
  setImportErrors: (errors: string[]) => void,
};

function ImportErrorsAlert({ importErrors, setImportErrors }: Props) {
  const formatedImportErrors = React.useMemo(() => (
    importErrors.map((err: string) => (
      err.match(/\[(?<ruleId>.+)\]: \[(?<message>.+)\]/).groups as ImportErrorsType
    ))
  ), [importErrors]);

  return (
    <StyledAlert bsStyle="warning"
                 style={{ width: '100%' }}
                 title={(
                   <Row $width="100%" $justify="space-between">
                     <h4><strong>Import Errors</strong></h4>
                     <IconButton size="xs" name="close" title="Clear errors" onClick={() => setImportErrors([])} />
                   </Row>
                 )}>
      <Col $width="100%" $gap="0.5rem">
        {formatedImportErrors.map((error: ImportErrorsType) => (
          <Row key={error.ruleId} $width="100%" $gap="0.5rem">
            <strong>{error.ruleId}:</strong>
            <span>{error.message}</span>
          </Row>
        ))}
      </Col>
    </StyledAlert>
  );
}

export default ImportErrorsAlert;
