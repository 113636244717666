import * as React from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_LAKE_API_ROUTES } from 'data-lake/Constants';
import UserNotification from 'util/UserNotification';
import DataLakeActionModalForm from 'data-lake/components/DataLakeActionModalForm';
import useUserDateTime from 'hooks/useUserDateTime';
import { getDataLakeUriParams } from 'data-lake/logic/Utils';
import { handleFailures } from 'data-lake/data-lake-overview/actions/Utils';
import type { DataLakeActionValues } from 'data-lake/Types';

type Props = {
  archiveStreamId: string
  onToggleModal: () => void
};

const DeleteArchiveModal = ({
  archiveStreamId,
  onToggleModal,
}: Props) => {
  const { userTimezone } = useUserDateTime();
  const queryClient = useQueryClient();

  const onDeleteSubmit = useCallback((values: DataLakeActionValues) => {
    const uriParams = getDataLakeUriParams(values, userTimezone);

    fetch(
      'POST',
      qualifyUrl(`${DATA_LAKE_API_ROUTES.DataLakeAPIController.bulk_delete().url}?${uriParams}`),
      { stream_ids: [archiveStreamId], inclusion_type: values.inclusion_type },
    ).then((response) => {
      const failures = response?.failures || [];
      handleFailures(failures, 'deleted');
    }).catch((error) => {
      UserNotification.error(`An error occurred while deleting archive. ${error}`);
    }).finally(() => {
      queryClient.invalidateQueries(['data-lake']);
      onToggleModal();
    });
  }, [userTimezone, archiveStreamId, queryClient, onToggleModal]);

  return (
    <DataLakeActionModalForm modalTitle="Deleting Data Lake data! Are you sure?"
                             type="delete"
                             show
                             onHide={onToggleModal}
                             submitButtonText="Delete"
                             hourOnly
                             streamIds={[archiveStreamId]}
                             onSubmit={onDeleteSubmit}>
      <p>You are about to delete data from 1 Data Lake. This action cannot be undone.</p>
    </DataLakeActionModalForm>
  );
};

export default DeleteArchiveModal;
