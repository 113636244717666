import { qualifyUrls } from 'routing/Routes';

const DataLakeRoutes = {
  ARCHIVE: {
    LIST: '/data-lake',
    show: (archiveId: string) => `/data-lake/${archiveId}`,
  },
  BACKEND: '/data-lake/backend',
  CONFIGURATION: '/data-lake/configuration',
  PREVIEW: '/data-storage/preview',
} as const;
const routes = qualifyUrls(DataLakeRoutes);

export default {
  ...routes,
  unqualified: DataLakeRoutes,
};
