import CoreRoutes, { qualifyUrls, SECURITY_PATH } from 'routing/Routes';

export const SECURITY_VIEW_ID_PREFIX = 'graylog-security-views__';

const Routes = {
  WELCOME: SECURITY_PATH,
  EXECUTIVE_OVERVIEW: `${SECURITY_PATH}/executive-overview`,
  ACTIVITY: {
    OVERVIEW: `${CoreRoutes.unqualified.SECURITY.ACTIVITY}`,
    USER_ACTIVITY: `${CoreRoutes.unqualified.SECURITY.ACTIVITY}/user-activity`,
    ASSET_DRILLDOWN: `${CoreRoutes.unqualified.SECURITY.ACTIVITY}/asset-drilldown`,
    HOST_ACTIVITY: `${CoreRoutes.unqualified.SECURITY.ACTIVITY}/host-activity`,
    NETWORK_ACTIVITY: `${CoreRoutes.unqualified.SECURITY.ACTIVITY}/network-activity`,
  },
  OVERVIEW: CoreRoutes.unqualified.SECURITY.OVERVIEW,
  USER_ACTIVITY: CoreRoutes.unqualified.SECURITY.USER_ACTIVITY,
  HOST_ACTIVITY: CoreRoutes.unqualified.SECURITY.HOST_ACTIVITY,
  NETWORK_ACTIVITY: CoreRoutes.unqualified.SECURITY.NETWORK_ACTIVITY,
  ANOMALIES: {
    LIST: CoreRoutes.unqualified.SECURITY.ANOMALIES,
    DETECTORS: {
      LIST: `${CoreRoutes.unqualified.SECURITY.ANOMALIES}/detectors`,
      CREATE: `${CoreRoutes.unqualified.SECURITY.ANOMALIES}/detectors/create`,
      edit: (detectorId: string) => `${CoreRoutes.SECURITY.ANOMALIES}/detector/edit/${detectorId}`,
    },
  },
  ASSETS: {
    CONFIGURATION: `${SECURITY_PATH}/assets/configuration`,
    list: (assetType: string) => `${SECURITY_PATH}/assets/${assetType}`,
    details: (assetType: string, assetId: string) => `${SECURITY_PATH}/assets/${assetType}/${assetId}`,
    SOURCES: {
      LIST: `${SECURITY_PATH}/assets/sources`,
      forms: (sourceType: string) => `${SECURITY_PATH}/assets/sources/${sourceType}/forms`,
      form: (sourceType: string, sourceId: string) => `${SECURITY_PATH}/assets/sources/${sourceType}/forms/${sourceId}`,
      formMappings: (sourceType: string, sourceId: string) => `${SECURITY_PATH}/assets/sources/${sourceType}/forms/${sourceId}/mappings`,
      formMapping: (sourceType: string, sourceId: string, mapId: string) => `${SECURITY_PATH}/assets/sources/${sourceType}/forms/${sourceId}/mappings/${mapId}`,
    },
    SCANNERS: {
      LIST: `${SECURITY_PATH}/assets/scanners`,
      show: (scannerId: string) => `${SECURITY_PATH}/assets/scanners/${scannerId}`,
    },
  },
  INVESTIGATIONS: {
    LIST: `${SECURITY_PATH}/investigations`,
    details: (investigationId: string) => `${SECURITY_PATH}/investigations/details/${investigationId}`,
    ARCHIVED: `${SECURITY_PATH}/investigations/archived`,
    CONFIGURATION: `${SECURITY_PATH}/investigations/configuration`,
    show: (investigationId: string) => `${SECURITY_PATH}/investigations/${investigationId}`,
  },
  SIGMA: {
    LIST: `${SECURITY_PATH}/sigma`,
    CONFIGURATION: `${SECURITY_PATH}/sigma/configuration`,
  },
  SECURITY_EVENTS: {
    ALERTS: {
      LIST: `${SECURITY_PATH}/security-events/alerts`,
      show: (eventId: string) => `${SECURITY_PATH}/security-events/alerts/${eventId}`,
    },
    DEFINITIONS: `${SECURITY_PATH}/security-events/definitions`,
    NOTIFICATIONS: `${SECURITY_PATH}/security-events/notifications`,
    BULK_REPLAY_SEARCH: `${SECURITY_PATH}/security-events/replay-search`,
  },
} as const;

export default qualifyUrls(Routes);
