import * as React from 'react';
import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';

import { Icon, ProgressBar, RelativeTime, ConfirmDialog, Timestamp } from 'components/common';
import { Badge, Button } from 'components/bootstrap';
import useParams from 'routing/useParams';

import { JobStatus, type DataLakeJob, JobType } from '../Types';

type Props = {
  job: DataLakeJob,
  onCancel: (id: string) => Promise<void>,
  onAcknowledge: (id: string) => Promise<void>,
};

const StatusBadge = styled(Badge)<{ status: string }>(({ status, theme }) => {
  const {
    primary,
    success,
    info,
    warning,
    danger,
  } = theme.colors.variant.dark;

  const statuses = {
    cancelled: warning,
    complete: success,
    error: danger,
    queued: info,
    running: primary,
  };

  const color = statuses[status] ?? info;

  return css`
    margin-left: ${theme.spacings.xs};
    background-color: ${color};
    color: ${theme.utils.readableColor(color)};
  `;
});

const StyledIcon = styled(Icon)(({ theme }) => css`
  margin-right: ${theme.spacings.xxs};
  display: flex;
  align-items: center;
`);

const StyledProgressBar = styled(ProgressBar)(({ theme }) => css`
  margin-top: ${theme.spacings.xs};
`);
const StyledSpan = styled.span(({ theme }) => css`
  margin-right: ${theme.spacings.xxs};
`);

const CancelButton = styled(Button)`
  margin-left: auto;
`;

const AcknowledgeButton = styled(Button)(({ theme }) => css`
  margin-left: auto;
  && {
    color: ${theme.colors.variant.light.default};

    &:hover {
      color: ${theme.colors.variant.default};
    }
  }
`);

const JobWrapper = styled.div(({ theme }) => css`
  display: flex;
  padding: ${theme.spacings.xxs} 0;
  margin-bottom: ${theme.spacings.xs};

  &:hover {
    background-color: ${theme.colors.variant.lighter.default};
  }
`);

const getJobActionDescription = (job: DataLakeJob) => {
  const archives = job.archive.join(', ');
  const archivesTimestamps = (job.timestamp_from && job.timestamp_to) ? <> (<Timestamp dateTime={job.timestamp_from} /> - <Timestamp dateTime={job.timestamp_to} />)</> : '';

  switch (job.job_type) {
    case JobType.Delete:
      return <>Deleting data in <code>{archives}{archivesTimestamps}</code> data lake started </>;
    case JobType.Optimize:
      if (job.archive.length === 0) {
        // The scheduled optimization trigger doesn't contain a list of archives.
        if (job.status === JobStatus.Runnable) {
          return <>Scheduled optimization job for all data lakes will run at </>;
        }

        return <>Scheduled optimization job for all data lakes started </>;
      }

      return <>Optimization operation <code>{job.action}</code> for data lakes <code>{archives}</code> started </>;
    case JobType.Restore:
      return <>Retrieve operation on data lake <code>{archives}{archivesTimestamps}</code> started </>;
    default:
      return <>Unknown job type <code>{job.job_type}</code> started </>;
  }
};

const Job = ({ job, onCancel, onAcknowledge }: Props) => {
  const { streamId } = useParams<{ streamId: string }>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [shouldRefetchOnJobComplete, setShouldRefetchOnJobComplete] = useState(false);
  const queryClient = useQueryClient();
  const jobIsOver = job.status === JobStatus.Complete || job.status === JobStatus.Cancelled
    || job.status === JobStatus.Error || job.status === JobStatus.Runnable;
  const jobStatus = job.status === JobStatus.Runnable ? 'queued' : job.status;

  const handleCancel = async () => {
    await onCancel(job.id);
    setShowConfirmDialog(false);
  };

  useEffect(() => {
    if (job.status === JobStatus.Running || job.status === JobStatus.Runnable) {
      setShouldRefetchOnJobComplete(true);
    }
  }, [job.status]);

  useEffect(() => {
    if (shouldRefetchOnJobComplete && job.status === JobStatus.Complete) {
      queryClient.invalidateQueries(['data-lake', streamId]);
    }
  }, [shouldRefetchOnJobComplete, job.status, queryClient, streamId]);

  return (
    <>
      <JobWrapper>
        <StyledIcon name="settings" />
        <StyledSpan>{getJobActionDescription(job)}</StyledSpan>
        <StyledSpan>
          {job.status === JobStatus.Runnable ? (
            <Timestamp dateTime={job.next_time} />
          ) : (
            <RelativeTime dateTime={job.started_at} />
          )}
        </StyledSpan>
        <StatusBadge status={job.status}>{jobStatus}</StatusBadge>
        {(!jobIsOver && job.is_cancelable) ? (
          <CancelButton type="button"
                        bsSize="xs"
                        bsStyle="primary"
                        className="pull-right"
                        onClick={() => setShowConfirmDialog(true)}>Cancel
          </CancelButton>
        ) : (
          job.is_ackable
          && (
            <AcknowledgeButton type="button"
                               bsStyle="link"
                               onClick={() => onAcknowledge(job.id)}
                               bsSize="xs"
                               className="pull-right"
                               title="Acknowledge"><Icon name="close" />
            </AcknowledgeButton>
          )
        )}
      </JobWrapper>
      {showConfirmDialog && (
      <ConfirmDialog show={showConfirmDialog}
                     onConfirm={handleCancel}
                     onCancel={() => setShowConfirmDialog(false)}
                     title="Cancel Data Lakes job">
        <p>
          You are about to cancel an Data Lake {job.job_type} job.
          This action cannot be undone.
        </p>
        <p>Are you sure you want to continue?</p>
      </ConfirmDialog>
      )}

      {!jobIsOver && <StyledProgressBar bars={[{ value: job.percent_done, bsStyle: 'info', animated: true }]} />}
    </>
  );
};

export default Job;
