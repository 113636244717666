import * as React from 'react';
import { useState, useCallback } from 'react';

import UserNotification from 'util/UserNotification';
import { Button } from 'components/bootstrap';
import { ConfirmDialog } from 'components/common';
import { deleteDataLakeBackend } from 'data-lake/data-lake-backend-config/hooks/useDataLakeBackendMutation';
import type { Backend } from 'archive/types';

type Props = {
  refetchDataLakeBackend: () => void,
  backend: Backend,
};

const DeleteDataLakeAction = ({ backend, refetchDataLakeBackend }: Props) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const onDeleteSubmit = useCallback(() => {
    deleteDataLakeBackend(backend.id).then(() => {
    }).catch((error) => {
      UserNotification.error(`An error occurred while deleting Data Lake backend. ${error}`);
    }).finally(() => {
      setShowConfirmDelete(false);
      refetchDataLakeBackend();
    });
  }, [backend, refetchDataLakeBackend]);

  const onDelete = () => {
    setShowConfirmDelete(true);
  };

  return (
    <>
      <Button bsStyle="danger"
              onClick={onDelete}>
        Delete
      </Button>
      {showConfirmDelete && (
      <ConfirmDialog title={`Deleting ${backend.title} Data Lake backend, are you sure?`}
                     show
                     onConfirm={onDeleteSubmit}
                     onCancel={() => setShowConfirmDelete(false)}>
        <p>{`You are about to remove ${backend.title} Data Lake backend. This action cannot be undone.`}</p>
      </ConfirmDialog>
      )}
    </>
  );
};

export default DeleteDataLakeAction;
