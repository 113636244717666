import React from 'react';

import { DocumentTitle, PageHeader } from 'components/common';
import { Alert, Row, Col } from 'components/bootstrap';
import DataLakeBackendButton from 'data-lake/data-lake-backend-config/DataLakeBackendButton';
import DataLakePageNavigation from 'data-lake/components/DataLakePageNavigation';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { DATA_LAKE_LICENSE_TITLE, DATA_LAKE_LICENSE_TEXT } from 'data-lake/Constants';

import BackendConfigOverview from './BackendConfigOverview';

const DataLakeBackendPage = () => (
  <DocumentTitle title="Data Lake Backend Configurations">
    <DataLakePageNavigation />
    <PageHeader title="Data Lake Backend Configurations"
                actions={<DataLakeBackendButton />}>
      <Alert bsStyle="info">
        Data Lake may use either an <b>S3 Bucket (recommended)</b> or <b>network storage</b> as a backend.<br />
        Note that only one Data Lake backend may be configured at a time, and when changing backend configuration, existing stored data will not be automatically moved to the new backend and will remain in the old one.<br />
        In this circumstance, data should be migrated between back-ends outside of the product.
      </Alert>
      <Alert bsStyle="warning">
        When using a filesystem - altering the permissions of files in the Data Lake  backend can result in corruption of data.
      </Alert>
    </PageHeader>
    <LicenseCheck licenseSubject={LICENSE_SUBJECTS.archive}
                  title={DATA_LAKE_LICENSE_TITLE}
                  text={DATA_LAKE_LICENSE_TEXT}
                  displayWarningContainer
                  bsStyle="danger" />
    <Row className="content">
      <Col md={12}>
        <BackendConfigOverview />
      </Col>
    </Row>
  </DocumentTitle>
);

export default DataLakeBackendPage;
