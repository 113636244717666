import React from 'react';

import DataLakePageNavigation from 'data-lake/components/DataLakePageNavigation';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import { Row, Col } from 'components/bootstrap';
import useDataLakeBackends from 'data-lake/data-lake-backend-config/hooks/useDataLakeBackends';
import useDataLakeConfig from 'data-lake/configurations/hooks/useDataLakeConfig';
import DataLakeConfigForm from 'data-lake/configurations/DataLakeConfigForm';

const DataLakeConfigPage = () => {
  const { data: backendsList, isInitialLoading: isLoadingBackends } = useDataLakeBackends({ enabled: true });
  const { data: config, isInitialLoading: isLoadingCurrentConfig } = useDataLakeConfig();

  if (isLoadingBackends || isLoadingCurrentConfig) {
    return <Spinner />;
  }

  return (
    <DocumentTitle title="Data Lake Configuration">
      <DataLakePageNavigation />
      <PageHeader title="Data Lake Configuration">
        <span>
          Manage Data Lake configuration
        </span>
      </PageHeader>
      <Row className="content">
        <Col md={12}>
          <DataLakeConfigForm backends={backendsList} config={config} />
        </Col>
      </Row>
    </DocumentTitle>
  );
};

export default DataLakeConfigPage;
