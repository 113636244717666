import moment from 'moment';
import type { unitOfTime } from 'moment';

import type { SearchFilter } from 'components/event-definitions/event-definitions-types';
import type { RuleOptionsType, RuleOptionsPayloadType } from 'security-app/hooks/api/sigmaAPI.types';

export const MAX_SEARCH_WITHIN = 7 * 24 * 60 * 60 * 1000; // 7 days in minutes in milliseconds
export const MIN_EXECUTE_EVERY = 5 * 60 * 1000; // 5 minutes in milliseconds

export const SEARCH_WITHIN_UNITS = ['HOURS', 'MINUTES', 'DAYS'];
export const EXECUTE_EVERY_UNITS = ['HOURS', 'MINUTES'];

const validateSearchWithin = (time: number, unit: string) => {
  const auxTime = moment.duration(time, unit as unitOfTime.DurationConstructor).asMilliseconds();
  if (auxTime > MAX_SEARCH_WITHIN) return 'Max value is 7 DAYS';
  if (auxTime < 0) return 'Invalid negative value';

  return null;
};

const validateExecuteEvery = (time: number, unit: string) => {
  const auxTime = moment.duration(time, unit as unitOfTime.DurationConstructor).asMilliseconds();
  if (auxTime < MIN_EXECUTE_EVERY) return 'Minimum value is 5 MINUTES';

  return null;
};

export const validate = (values: RuleOptionsType) => {
  const errors: { search_within?: string, execute_every?: string } = {};

  const searchWithinError = validateSearchWithin(values.search_within, values.search_within_unit);
  const executeEveryError = validateExecuteEvery(values.execute_every, values.execute_every_unit);

  if (searchWithinError) errors.search_within = searchWithinError;
  if (executeEveryError) errors.execute_every = executeEveryError;

  return errors;
};

export const formOptionsToPayload = (formRule: RuleOptionsType): RuleOptionsPayloadType => {
  const toMilliseconds = (time: number, unit: string) => {
    let milliseconds = time * 1000;
    if (unit === 'MINUTES' || unit === 'HOURS' || unit === 'DAYS') milliseconds *= 60;
    if (unit === 'HOURS' || unit === 'DAYS') milliseconds *= 60;
    if (unit === 'DAYS') milliseconds *= 24;

    return milliseconds;
  };

  return {
    search_within_ms: toMilliseconds(formRule.search_within, formRule.search_within_unit),
    execute_every_ms: toMilliseconds(formRule.execute_every, formRule.execute_every_unit),
    use_cron_scheduling: formRule.use_cron_scheduling,
    cron_expression: formRule.cron_expression,
    cron_timezone: formRule.cron_timezone,
    streams: formRule.streams,
    stream_categories: formRule.stream_categories,
    notifications: formRule.notifications,
    filters: formRule.filters as SearchFilter[],
    remediation_steps: formRule.remediation_steps,
  };
};
