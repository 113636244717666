import AppConfig from 'util/AppConfig';
import { qualifyUrls } from 'routing/Routes';

const EnterpriseRoutes = {
  OVERVIEW: '/system/enterprise',
  ARCHIVES: '/system/archives',
  DATA_LAKE: '/data-lake',
  AUDIT_LOG: '/system/auditlog',
  LICENSES: '/system/licenses',
  REPORTS: '/reports',
  CUSTOMIZATION: '/customization',
  FORWARDERS: '/system/forwarders',
  ILLUMINATE: '/illuminate',
  MY_SEARCH_FILTERS: '/my-filters',
} as const;

export default qualifyUrls(EnterpriseRoutes, AppConfig.gl2AppPathPrefix());
