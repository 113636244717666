import * as React from 'react';
import { useState, useCallback } from 'react';
import isNumber from 'lodash/isNumber';

import { Button } from 'components/bootstrap';
import { Icon, HoverForHelp, Pluralize } from 'components/common';
import AIReportModal from 'security-app/components/Investigations/AIReport/AIReportModal';
import type { InvestigationAPIType, InvestigationsDetailsAPIType } from 'security-app/hooks/api/investigationsAPI.types';

const MINIMAL_LOGS_TO_GENERATE_REPORT = 3;

const AIReportButton = ({ investigation, small = false, logsCount }: { investigation: InvestigationAPIType | InvestigationsDetailsAPIType, small?: boolean, logsCount: number }) => {
  const [showAIReportModal, setShowAIReportModal] = useState(false);
  const onGetAIReport = useCallback(() => {
    setShowAIReportModal(true);
  }, []);

  const hasNotEnoughLogs = isNumber(logsCount) && logsCount < MINIMAL_LOGS_TO_GENERATE_REPORT;

  return (
    <>
      <Button disabled={hasNotEnoughLogs} bsStyle="primary" onClick={onGetAIReport} title="Generate AI Report" bsSize={small ? 'sm' : undefined}>
        <Icon name="note_add" type="regular" size={small ? 'sm' : undefined} /> {!small && 'AI Report'}
        {hasNotEnoughLogs && (
        <HoverForHelp title="Add more logs">
          At least {MINIMAL_LOGS_TO_GENERATE_REPORT}
          {' '}
          <Pluralize value={MINIMAL_LOGS_TO_GENERATE_REPORT} singular="log evidence is" plural="log evidences are" /> required to generate an AI report.
        </HoverForHelp>
        )}
      </Button>
      {showAIReportModal && <AIReportModal isConsent={false} onCancel={() => setShowAIReportModal(false)} investigation={investigation} />}
    </>
  );
};

export default AIReportButton;
