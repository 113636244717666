import type { MimecastGenericInputCreateRequest, MimecastInputCreateRequest } from './types';

import type { FormDataType } from '../common/utils/types';

export const toMimecastInputCreateRequest = ({
  baseUrl,
  mimecastName,
  accessKey,
  secretKey,
  applicationId,
  applicationKey,
  contentTypes,
  mimecastThrottleEnabled,
  pollingInterval,
}: FormDataType): MimecastInputCreateRequest => ({
  base_url: baseUrl?.value,
  name: mimecastName?.value,
  access_key: accessKey?.value,
  secret_key: secretKey?.value,
  application_id: applicationId?.value,
  application_key: applicationKey?.value,
  content_type: contentTypes?.value?.split(','),
  enable_throttling: !!mimecastThrottleEnabled?.value,
  polling_interval: pollingInterval?.value,
  polling_time_unit: 'MINUTES',
});

export const toGenericInputCreateRequest = ({
  mimecastName,
  baseUrl,
  accessKey,
  secretKey,
  applicationId,
  applicationKey,
  mimecastThrottleEnabled,
  pollingInterval,
  contentTypes,
}: FormDataType): MimecastGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.mimecast.MimecastInput',
  title: mimecastName.value,
  global: false,
  configuration: {
    base_url: baseUrl.value,
    access_key: accessKey.value,
    secret_key: secretKey.value,
    application_id: applicationId.value,
    application_key: applicationKey.value,
    content_type: contentTypes.value.split(','),
    throttling_allowed: !!mimecastThrottleEnabled?.value,
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
  },
});
