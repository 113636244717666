import * as React from 'react';

import MDEditorFullView from 'security-app/components/common/MDEditor/MDEditorFullView';
import { useModalContext } from 'common/contexts';
import { useUpdateSecurityEvent } from 'security-app/hooks/useSecurityEventsAPI';
import useSendEventActionTelemetry from 'components/events/events/hooks/useSendEventActionTelemetry';

function NotesModal() {
  const { entity: securityEvent, setEntity, setModal } = useModalContext();
  const { updateSecurityEvent } = useUpdateSecurityEvent();
  const sendEventActionTelemetry = useSendEventActionTelemetry();

  const onConfirm = async () => {
    const payload = {
      owner: securityEvent.owner,
      notes: securityEvent.notes,
      status: securityEvent.status,
    };

    await updateSecurityEvent({ id: securityEvent.event_id, data: payload });
    sendEventActionTelemetry('ADD_NOTES', false);

    setModal(null);
    setEntity(null);
  };

  const onCancel = () => {
    setModal(null);
    setEntity(null);
  };

  const handleNoteChange = (newValue: string) => {
    setEntity({ ...securityEvent, notes: newValue });
  };

  return (
    <MDEditorFullView show
                      value={securityEvent.notes}
                      onChange={handleNoteChange}
                      onClose={onCancel}
                      onDone={onConfirm} />
  );
}

export default NotesModal;
