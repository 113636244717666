import * as React from 'react';

import { EmptyList, TD, RowTitle } from 'security-app/components/common';
import { GLCheckbox } from 'common/components';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import { usePagination, useSelectedRows, useSelectedRowsDispatch } from 'common/contexts';

type Props = {
  investigations: InvestigationAPIType[],
};

function ListRows({ investigations }: Props) {
  const localPagination = usePagination();
  const selectedInvestigations = useSelectedRows();
  const selectedInvestigationsDispatch = useSelectedRowsDispatch();

  const onChange = (investigation: InvestigationAPIType) => (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      selectedInvestigationsDispatch({ type: 'add', payload: [investigation] });
    } else {
      selectedInvestigationsDispatch({ type: 'remove', payload: [investigation] });
    }
  };

  const onNameClick = (investigation: InvestigationAPIType) => () => {
    if (selectedInvestigations.find((sInv: InvestigationAPIType) => sInv.id === investigation.id)) {
      selectedInvestigationsDispatch({ type: 'remove', payload: [investigation] });
    } else {
      selectedInvestigationsDispatch({ type: 'add', payload: [investigation] });
    }
  };

  const isSelected = (investigation: InvestigationAPIType) => (
    !!selectedInvestigations.find((sInv: InvestigationAPIType) => investigation.id === sInv.id)
  );

  return investigations.length < 1 ? (
    <EmptyList query={localPagination.query}
               filters={localPagination.filters}
               noSearchResultText="No Investigations found for the provided criteria"
               noEntitiesExist="Currently there are no Investigations to list"
               colSpan={3} />
  ) : (
    <>
      {investigations.map((investigation: InvestigationAPIType) => (
        <tr key={`investigation-row-${investigation.id}`} data-testid="open-investigation-row">
          <TD><GLCheckbox checked={isSelected(investigation)}
                          onChange={onChange(investigation)}
                          data-testid="investigation-checkbox" />
          </TD>
          <TD data-testid="investigation-name" role="checkbox">
            <RowTitle onClick={onNameClick(investigation)}>
              {investigation.name}
            </RowTitle>
          </TD>
          <TD>{investigation.status}</TD>
        </tr>
      ))}
    </>
  );
}

export default ListRows;
