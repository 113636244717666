import * as React from 'react';

import { GLCheckbox } from 'common/components';
import { useSelectedRows, useSelectedRowsDispatch } from 'common/contexts';
import type { GitRepoContent, GitRepoAPIModel } from 'security-app/hooks/api/sigmaAPI.types';

import PathNav from './PathNav';

import { WindowHeader } from '../styledComponents';

type Props = {
  repoRules: GitRepoContent[],
  repo: GitRepoAPIModel,
  repoPath: string,
  setRepoPath: (newPath: string) => void,
  setRepo: (newRepo: GitRepoAPIModel) => void,
};

function PanelHeader({ repoRules, repo, repoPath, setRepoPath, setRepo }: Props) {
  const selectedRules = useSelectedRows();
  const selectedRulesDispatch = useSelectedRowsDispatch();

  const ruleFiles = React.useMemo(() => repoRules.filter((rule: GitRepoContent) => rule.type === 'file'), [repoRules]);

  const selectAll = (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      selectedRulesDispatch({ type: 'add', payload: ruleFiles });
    } else {
      selectedRulesDispatch({ type: 'remove', payload: ruleFiles });
    }
  };

  const allSelected = React.useMemo(() => (
    ruleFiles.length > 0
    && selectedRules.length > 0
    && ruleFiles.every((rule: GitRepoContent) => (
      !!selectedRules.find((sRule: GitRepoContent) => rule.id === sRule.id)
    ))
  ), [ruleFiles, selectedRules]);

  return (
    <WindowHeader data-testid="path-title">
      {ruleFiles.length > 0 && (
        <GLCheckbox checked={allSelected} onChange={selectAll} title="Select all rules" />
      )}
      <PathNav repo={repo} repoPath={repoPath} setRepoPath={setRepoPath} setRepo={setRepo} />
    </WindowHeader>
  );
}

export default PanelHeader;
