import { DataWarehouseQuery } from '@graylog/enterprise-api';

import type { LogViewMessage } from 'logview/types';
import type { FieldsOperator, FieldFilters } from 'data-lake/Types';
import { defaultCompare } from 'logic/DefaultCompare';
import type { TimeRange } from 'views/logic/queries/Query';

const TIMEOUT = 60;
export const PAGE_SIZE = 100;

type SearchResultResponse = {
  messages: Array<{ message: { [fieldName: string]: unknown } }>,
};

const isLogViewMessage = (message: { message: { [fieldName: string]: unknown } }): message is LogViewMessage => (
  '_id' in message.message && 'timestamp' in message.message
);

const formatMessages = (
  response: SearchResultResponse,
): Array<LogViewMessage> => response.messages?.sort(
  ({ message: { timestamp: timestamp1 } },
    { message: { timestamp: timestamp2 } }) => defaultCompare(timestamp1, timestamp2),
).map(({ message }) => {
  const { id, timestamp, ...rest } = message;
  const logViewMessage = ({ message: { ...rest, _id: id, timestamp } });

  if (!isLogViewMessage(logViewMessage)) {
    throw Error('A message is missing the id or timestamp attribute.');
  }

  return logViewMessage;
});

export const fetchSearchResults = (
  { fields, stream, timerange, searchAfter, fieldFilters, fieldsOperator }:
  { fields: Array<string>; stream: string, timerange: TimeRange, searchAfter?: { id: string, timestamp: string }, fieldFilters: Array<FieldFilters>, fieldsOperator: FieldsOperator },
) => DataWarehouseQuery.query({
  fields,
  inclusion_type: 'BOTH',
  operator: fieldsOperator,
  limit: PAGE_SIZE,
  search_after: searchAfter,
  stream_id: stream,
  timeout: TIMEOUT,
  timerange,
  field_filters: fieldFilters ?? [],
}).then((result) => formatMessages(result));

export default fetchSearchResults;
