import * as React from 'react';

import { useGetStreams, useGetNotifications } from 'security-app/hooks/useSigmaAPI';

import type { OptionsType } from './types';

export function useStreamOptions() {
  const { streams, streamCategories, loadingStreams } = useGetStreams();

  const streamOptions: OptionsType[] = React.useMemo(() => (
    streams.filter((stream: { is_editable: boolean }) => stream.is_editable)
      .map((stream: { id: string, title: string }) => ({ label: stream.title, value: stream.id }))
  ), [streams]);

  const streamCategoryOptions: OptionsType[] = React.useMemo(() => (
    streamCategories.map((category: string) => ({ label: category, value: category }))
  ), [streamCategories]);

  return { loadingStreams, streamOptions, streamCategoryOptions };
}

export function useNotificationOptions() {
  const { notifications, loadingNotifications } = useGetNotifications();

  const notificationOptions: OptionsType[] = React.useMemo(() => (
    notifications.map((notification: { id: string, title: string; }) => ({ label: notification.title, value: notification.id }))
  ), [notifications]);

  return { loadingNotifications, notificationOptions };
}
