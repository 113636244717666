import { useState } from 'react';
import { useQuery, useQueryClient, useMutation, useQueries } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import type { ColumnFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';
import { defaultOnError, onError } from 'util/conditional/onError';

import {
  fetchAssets,
  fetchAsset,
  getAssetsById,
  deleteAsset,
  createAsset,
  updateAsset,
  fetchPriorities,
  fetchCategories,
  addPriority,
  updatePriority,
  reprioritizePriorities,
  deletePriority,
  addCategory,
  updateCategory,
  deleteCategory,
  bulkAddCategories,
  fetchAssetSources,
  createAssetSource,
  updateAssetSource,
  deleteAssetSource,
  testNewAssetSource,
  fetchAssetSourceMappings,
  createAssetSourceMapping,
  updateAssetSourceMapping,
  deleteAssetSourceMapping,
  importAssets,
  testSavedAssetSource,
  bulkTestAssetSource,
  testNewAssetSrouceMapping,
  testSavedAssetSourceMapping,
  fetchAssetSource,
  fetchMS365DeploymentTypes,
  fetchAssetSourceMapping,
  toggleMappingSync,
  fetchScanners,
  fetchScannerDetails,
  addScanner,
  deleteScanner,
  editScanner,
  toggleScannerSync,
  testNewScannerConnection,
  testScannerConnection,
  importVulnerabilityScans,
  fetchAssetsFilterOptions,
} from './api/assetsAPI';
import type {
  AssetsIndexAPIType,
  AssetAPIType,
  PriorityAPIType,
  CategoryAPIType,
  AssetDetailsType,
  AssetSourceIndexAPIType,
  AssetSourceMappingsIndexAPIType,
  AssetSourceTestAPIType,
  AssetSourceAPIType,
  AssetSourceConfigTypes,
  AssetSourceMS365MappingConfigsType,
  AssetSourceMappingAPIType,
  AssetSourceMappingConfigsType,
  ScannerIndexAPIType,
  ScannerAPIType,
  ImportNessusResultAPIType,
  MS365DeploymentTypeList,
  MS365DeploymentType,
  ScannerConfigAPITypes,
} from './api/assetsAPI.types';

export type PaginatedProps = {
  page: number,
  perPage: number,
  query?: string,
  orderBy?: string,
  direction?: 'asc' | 'desc',
  filters?: ColumnFilterData,
};

export function useGetAssets({
  page,
  perPage,
  query,
  orderBy,
  direction,
  filters,
}: PaginatedProps) {
  const { data, isLoading } = useQuery<AssetsIndexAPIType, Error>(
    ['get-assets', page, perPage, query, orderBy, direction, filters],
    () => defaultOnError(fetchAssets(page, perPage, query, orderBy, direction, filters), 'Error fetching assets'),
    {
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingAssets: isLoading,
    assets: isLoading ? [] : data?.assets,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useFetchAssetsFilterOptions() {
  const { mutateAsync, isLoading } = useMutation(
    fetchAssetsFilterOptions,
    {
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    fetchAssetsFilterOptions: mutateAsync,
    fetchingAssetsFilterOptions: isLoading,
  };
}

export function useGetAsset(assetId: string) {
  const { data, isFetching } = useQuery<AssetAPIType<AssetDetailsType>, Error>(
    ['get-asset', assetId],
    () => defaultOnError(fetchAsset(assetId), 'Error fetching asset'),
    {
      retry: 1,
      enabled: !!assetId,
    },
  );

  return {
    asset: isFetching ? null : data,
    loadingAsset: isFetching,
  };
}

export function useGetAssetsByIds(assetIds: string[]) {
  const { data, isFetching } = useQuery<AssetsIndexAPIType, Error>(
    ['get-asset-by-ids', assetIds],
    () => defaultOnError(getAssetsById(assetIds.join()), 'Error fetching assets'),
    {
      retry: 1,
    },
  );

  return {
    assets: isFetching ? [] : data?.assets,
    fetchingAssets: isFetching,
  };
}

export function useCreateAsset() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    createAsset,
    {
      onSuccess: () => {
        UserNotification.success('Asset created successfully');
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    createAsset: mutateAsync,
    creatingAsset: isLoading,
  };
}

export function useUpdateAsset() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updateAsset,
    {
      onSuccess: () => {
        UserNotification.success('Asset updated successfully');
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    updateAsset: mutateAsync,
    updatingAsset: isLoading,
  };
}

export function useDeleteAsset() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deleteAsset,
    {
      onSuccess: () => {
        UserNotification.success('Asset deleted successfully');
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    deleteAsset: mutateAsync,
    deletingAsset: isLoading,
  };
}

export function useGetPriorities(execute: boolean = true) {
  const { data, isLoading } = useQuery<PriorityAPIType[], Error>(
    ['get-all-priorities'],
    () => defaultOnError(fetchPriorities(), 'Error fetching priorities'),
    {
      retry: 2,
      enabled: execute,
    },
  );

  return {
    priorities: data || [],
    loadingPriorities: isLoading,
  };
}

export function useGetCategories(execute: boolean = true) {
  const { data, isLoading } = useQuery<CategoryAPIType[], Error>(
    ['get-all-categories'],
    () => defaultOnError(fetchCategories(), 'Error fetching categories'),
    {
      retry: 2,
      enabled: execute,
    },
  );

  return {
    categories: data || [],
    loadingCategoryes: isLoading,
  };
}

export function useAddPriority() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    addPriority,
    {
      onSuccess: () => {
        UserNotification.success('New priority tag added');
        queryClient.invalidateQueries(['get-all-priorities']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { addPriority: mutateAsync, addingPriority: isLoading };
}

export function useUpdatePriority() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updatePriority,
    {
      onSuccess: () => {
        UserNotification.success('Priority tag updated');
        queryClient.invalidateQueries(['get-all-priorities']);
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { updatePriority: mutateAsync, updatingPriority: isLoading };
}

export function useReprioritizePriorities() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    reprioritizePriorities,
    {
      onSuccess: () => {
        UserNotification.success('Priority tags updated');
        queryClient.invalidateQueries(['get-all-priorities']);
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { reprioritizePriorities: mutateAsync, reprioritizingPriorities: isLoading };
}

export function useDeletePriority() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deletePriority,
    {
      onSuccess: () => {
        UserNotification.success('Priority tag deleted');
        queryClient.invalidateQueries(['get-all-priorities']);
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { deletePriority: mutateAsync, deletingPriority: isLoading };
}

export function useAddCategory() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    addCategory,
    {
      onSuccess: () => {
        UserNotification.success('New Category tag added');
        queryClient.invalidateQueries(['get-all-categories']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { addCategory: mutateAsync, addingCategory: isLoading };
}

export function useUpdateCategory() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updateCategory,
    {
      onSuccess: () => {
        UserNotification.success('Category tag updated');
        queryClient.invalidateQueries(['get-all-categories']);
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { updateCategory: mutateAsync, updatingCategory: isLoading };
}

export function useDeleteCategory() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deleteCategory,
    {
      onSuccess: () => {
        UserNotification.success('Category tag deleted');
        queryClient.invalidateQueries(['get-all-categories']);
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { deleteCategory: mutateAsync, deletingCategory: isLoading };
}

export function useBulkAddCategories() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(
    bulkAddCategories,
    {
      onSuccess: () => {
        UserNotification.success('Assets updated successfully');
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { bulkAddCategories: mutateAsync };
}

/* Asset Sources */
export function useGetAssetSources({
  page,
  perPage,
  query,
  orderBy,
  direction,
  filters,
}: PaginatedProps) {
  const { data, isLoading } = useQuery<AssetSourceIndexAPIType, Error>(
    ['get-assets-sources', page, perPage, query, orderBy, direction, filters],
    () => defaultOnError(fetchAssetSources(page, perPage, query, orderBy, direction, filters), 'Error fetching asset sources'),
    {
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingAssetSources: isLoading,
    assetSources: isLoading ? [] : data?.asset_source_backend_configs,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useGetAssetSource(sourceId: string) {
  const { data, isFetching } = useQuery<AssetSourceAPIType<AssetSourceConfigTypes>, Error>(
    ['get-asset-source', sourceId],
    () => defaultOnError(fetchAssetSource(sourceId), 'Error fetching asset source'),
    {
      retry: 2,
      enabled: !!sourceId,
    },
  );

  return {
    assetSource: data || null,
    loadingAssetSource: isFetching,
  };
}

export function useGetMS365DeploymentTypes() {
  const { data, isFetching } = useQuery<MS365DeploymentTypeList, Error>(
    ['get-ms365-deployment-types'],
    () => defaultOnError(fetchMS365DeploymentTypes(), 'Error fetching MS365 deployment types'),
    {
      retry: 2,
    },
  );

  return {
    ms365DeploymentTypes: data?.types || [],
    ms365DeploymentTypesMap: data?.types.reduce((acc: { [key: string]: string }, dt: MS365DeploymentType) => {
      acc[dt.value] = dt.display_name;

      return acc;
    }, {} as { [key: string]: string }) || {},
    loadingMS365DeploymentTypes: isFetching,
  };
}

export function useCreateAssetSource() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    createAssetSource,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source created successfully');
        queryClient.invalidateQueries(['get-asset-source']);
        queryClient.invalidateQueries(['get-assets-sources']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    createAssetSource: mutateAsync,
    creatingAssetSource: isLoading,
  };
}

export function useUpdateAssetSource() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updateAssetSource,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source updated successfully');
        queryClient.invalidateQueries(['get-asset-source']);
        queryClient.invalidateQueries(['get-assets-sources']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    updateAssetSource: mutateAsync,
    updatingAssetSource: isLoading,
  };
}

export function useDeleteAssetSource() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deleteAssetSource,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source deleted successfully');
        queryClient.invalidateQueries(['get-assets-sources']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    deleteAssetSource: mutateAsync,
    deletingAssetSource: isLoading,
  };
}

/* Asset Source Mappings */
export function useGetAssetSourceMappings({
  page,
  perPage,
}: PaginatedProps, sourceId: string) {
  const { data, isLoading } = useQuery<AssetSourceMappingsIndexAPIType, Error>(
    ['get-asset-source-mappings', sourceId, page, perPage],
    () => defaultOnError(fetchAssetSourceMappings(sourceId, page, perPage), 'Error fetching asset source mappings'),
    {
      retry: 2,
      enabled: !!sourceId,
      keepPreviousData: true,
    },
  );

  return {
    loadingAssetSourceMappings: isLoading,
    assetSourceMappings: isLoading ? [] : data?.asset_import_backend_configs,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useGetAssetSourceMapping(sourceId: string, mappingId: string) {
  const { data, isLoading } = useQuery<AssetSourceMappingAPIType<AssetSourceMappingConfigsType | AssetSourceMS365MappingConfigsType>, Error>(
    ['get-asset-source-mapping', sourceId, mappingId],
    () => defaultOnError(fetchAssetSourceMapping(sourceId, mappingId), 'Error fetching asset source mapping'),
    {
      retry: 2,
      enabled: !!mappingId,
    },
  );

  return {
    assetSourceMapping: data || null,
    loadingAssetSourceMapping: isLoading,
  };
}

export function useCreateAssetSourceMapping() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    createAssetSourceMapping,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source Mapping added successfully');
        queryClient.invalidateQueries(['get-asset-source-mappings']);
      },
      onError: (error: any) => {
        if ('failed' in error) {
          UserNotification.error(
            Object.entries(error.errors).map(([key, errors]: [string, string[]]) => `${key}: ${errors.join('\n')}`).join('\n'),
            'API validation failed',
          );
        } else UserNotification.error(error.message);
      },
    },
  );

  return {
    createAssetSourceMapping: mutateAsync,
    creatingAssetSourceMapping: isLoading,
  };
}

export function useUpdateAssetSourceMapping() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updateAssetSourceMapping,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source Mapping updated successfully');
        queryClient.invalidateQueries(['get-asset-source-mappings']);
      },
      onError: (error: any) => {
        if ('failed' in error) {
          UserNotification.error(
            Object.entries(error.errors).map(([key, errors]: [string, string[]]) => `${key}: ${errors.join('\n')}`).join('\n'),
            'API validation failed',
          );
        } else UserNotification.error(error.message);
      },
    },
  );

  return {
    updateAssetSourceMapping: mutateAsync,
    updatingAssetSourceMapping: isLoading,
  };
}

export function useDeleteAssetSourceMapping() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deleteAssetSourceMapping,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source Mapping deleted successfully');
        queryClient.invalidateQueries(['get-asset-source-mappings']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    deleteAssetSourceMapping: mutateAsync,
    deletingAssetSourceMapping: isLoading,
  };
}

export function useImportAssetsFromSource() {
  const [queryKey, setQueryKey] = useState<string[]>();
  const queryClient = useQueryClient();

  return {
    importAssets: async (sourceId: string, mappingId: string) => (new Promise((resolve: (arg: AssetSourceTestAPIType) => void) => {
      setQueryKey(['import-assets', sourceId, mappingId]);

      try {
        queryClient.fetchQuery<AssetSourceTestAPIType>({
          queryKey: ['import-assets', sourceId, mappingId],
          queryFn: () => importAssets(sourceId, mappingId),
        }).then((importData) => {
          if (importData.success === false) {
            UserNotification.error(importData.message, 'Import failed');
          } else {
            UserNotification.success('Successfully imported assets for current mapping');
            queryClient.invalidateQueries(['get-assets-sources-mappings']);
            queryClient.invalidateQueries(['get-asset-source']);
          }

          resolve(importData);
        });
      } catch (error) {
        UserNotification.error(error.message);
        resolve(error);
      }
    })),
    importingAssets: queryClient.getQueryState(queryKey)?.fetchStatus === 'fetching',
  };
}

/* Asset Source Tests */
export function useTestNewAssetSource() {
  const { mutateAsync, isLoading } = useMutation(
    testNewAssetSource,
    {
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    testNewAssetSource: mutateAsync,
    testingNewAssetSource: isLoading,
  };
}

export function useTestSavedAssetSource(sourceId: string) {
  const { data, isLoading } = useQuery<AssetSourceTestAPIType, Error>(
    ['test-saved-asset-source', sourceId],
    () => defaultOnError(testSavedAssetSource(sourceId), 'Error testing saved asset source'),
    {
      retry: 2,
      enabled: !!sourceId,
    },
  );

  return {
    testSavedAssetSource: data,
    testingSavedAssetSource: isLoading,
  };
}

export function useTestNewAssetSourceMapping() {
  const { mutateAsync, isLoading } = useMutation(
    testNewAssetSrouceMapping,
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
    },
  );

  return {
    testAssetSourceNewMapping: mutateAsync,
    testingAssetSourceNewMapping: isLoading,
  };
}

export function useBulkTestAssetSource() {
  const { mutateAsync, isLoading } = useMutation(
    bulkTestAssetSource,
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
    },
  );

  return {
    testAssetSources: mutateAsync,
    testingAssetSources: isLoading,
  };
}

export function useTestSavedAssetSourceMapping() {
  const [queryKey, setQueryKey] = useState<string[]>();
  const queryClient = useQueryClient();

  return {
    testSavedAssetSourceMapping: async (sourceId: string, mappingId: string) => (new Promise((resolve: (testResult: AssetSourceTestAPIType) => void) => {
      setQueryKey(['test-saved-asset-source-mapping', sourceId, mappingId]);

      try {
        queryClient.fetchQuery<AssetSourceTestAPIType>({
          queryKey: ['test-saved-asset-source-mapping', sourceId, mappingId],
          queryFn: () => testSavedAssetSourceMapping(sourceId, mappingId),
        }).then((testResponse) => {
          resolve(testResponse);
        });
      } catch (error) {
        UserNotification.error(error.message);
      }
    })),
    testingSavedAssetSourceMapping: queryClient.getQueryState(queryKey)?.fetchStatus === 'fetching',
  };
}

export function useToggleMappingSync() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    toggleMappingSync,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source Mapping sync updated successfully');
        queryClient.invalidateQueries(['get-asset-source-mappings']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
    },
  );

  return {
    toggleMappingSync: mutateAsync,
    togglingMappingSync: isLoading,
  };
}

/**
  * Vulnerability Scanners
* */
export function useGetScanners({
  page,
  perPage,
  query,
  orderBy,
  direction,
  filters,
}: PaginatedProps) {
  const { data, isLoading } = useQuery<ScannerIndexAPIType, Error>(
    ['get-scanners', page, perPage, query, orderBy, direction, filters],
    () => defaultOnError(fetchScanners(page, perPage, query, orderBy, direction, filters), 'Error fetching scanners'),
    {
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingScanners: isLoading,
    scanners: isLoading ? [] : data?.vulnerability_scanners ?? [],
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useGetScannerDetails(scannerIds: string[]) {
  const results = useQueries<ScannerAPIType<ScannerConfigAPITypes>[]>({
    queries: scannerIds.map((scannerId: string) => ({
      queryKey: ['get-scanner-details', scannerId],
      queryFn: () => onError(fetchScannerDetails(scannerId), (error: Error) => {
        if (!error.message.match(/not found/i)) UserNotification.error(error.message);
      }),
    })),
  });

  const { data, isLoading } = results.reduce((acc, result) => {
    if (Array.isArray(acc.data)) acc.data.push(result.data);
    else acc.data = [result.data];
    acc.isLoading = results.some((res) => res.isLoading);

    return acc;
  }, { data: [], isLoading: false });

  return {
    loadingScannerDetails: isLoading,
    scannerDetails: data,
  };
}

export function useAddScanner() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    addScanner,
    {
      onSuccess: () => {
        UserNotification.success('Scanner added successfully');
        queryClient.invalidateQueries(['get-scanners']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    addScanner: mutateAsync,
    addingScanner: isLoading,
  };
}

export function useDeleteScanner() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deleteScanner,
    {
      onSuccess: () => {
        UserNotification.success('Scanner deleted successfully');
        queryClient.invalidateQueries(['get-scanners']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    deleteScanner: mutateAsync,
    deletingScanner: isLoading,
  };
}

export function useEditScanner() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    editScanner,
    {
      onSuccess: () => {
        UserNotification.success('Scanner updated successfully');
        queryClient.invalidateQueries(['get-scanners']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    editScanner: mutateAsync,
    editingScanner: isLoading,
  };
}

export function useToggleScannerSync() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    toggleScannerSync,
    {
      onSuccess: () => {
        UserNotification.success('Scanner updated successfully');
        queryClient.invalidateQueries(['get-scanners']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    toggleScannerSync: mutateAsync,
    togglingScannerSync: isLoading,
  };
}

export function useTestNewScannerConnection() {
  const { mutateAsync, isLoading } = useMutation(
    testNewScannerConnection,
    {
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    testNewScannerConnection: mutateAsync,
    testingNewScannerConnection: isLoading,
  };
}

export function useTestScannerConnection() {
  const { mutateAsync, isLoading } = useMutation(
    testScannerConnection,
    {
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    testScannerConnection: mutateAsync,
    testingScannerConnection: isLoading,
  };
}

export function useImportVulnerabilityScans() {
  const [queryKey, setQueryKey] = useState<string[]>();
  const queryClient = useQueryClient();

  return {
    importVulnerabilityScans: async (scannerId: string) => (new Promise((resolve: (importResult: ImportNessusResultAPIType) => void) => {
      setQueryKey(['import-vulnerability-scans', scannerId]);

      try {
        queryClient.fetchQuery<ImportNessusResultAPIType>({
          queryKey: ['import-vulnerability-scans', scannerId],
          queryFn: () => importVulnerabilityScans(scannerId),
        }).then((importResult) => {
          queryClient.invalidateQueries(['get-scanners']);
          if (importResult.success) UserNotification.success(importResult.message);
          if (!importResult.success) UserNotification.error(importResult.errors.join(', '), importResult.message);
          resolve(importResult);
        });
      } catch (error) {
        UserNotification.error(error.message);
      }
    })),
    importingVulnerabilityScans: queryClient.getQueryState(queryKey)?.fetchStatus === 'fetching',
  };
}
