import { useContext } from 'react';

import TableFetchContext from 'components/common/PaginatedEntityTable/TableFetchContext';

const useRefetchTable = () => {
  const tableFetchContext = useContext(TableFetchContext);

  return tableFetchContext?.refetch ?? (() => {});
};

export default useRefetchTable;
