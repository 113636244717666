import * as React from 'react';

import FieldSelectBase from 'views/components/aggregationwizard/FieldSelectBase';
import useFieldTypes from 'data-lake/hooks/useFieldTypes';

type Props = Omit<React.ComponentProps<typeof FieldSelectBase>, 'options'>

const FieldSelect = (props: Props) => {
  const { data: widgetFields } = useFieldTypes();

  return (
    <FieldSelectBase options={widgetFields}
                     {...props} />
  );
};

export default FieldSelect;
