import * as React from 'react';

import { ReadOnlyFormGroup } from 'components/common';

type Props = {
  dataLakeEnabled: boolean;
}

const DataLakeStatus = ({ dataLakeEnabled }: Props) => (
  <ReadOnlyFormGroup label="Enabled" value={dataLakeEnabled || false} />
);

export default DataLakeStatus;
